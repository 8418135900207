import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Container,
} from "reactstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useSelector, useDispatch } from "react-redux";

import { API_URL } from "helpers/ENVState";
import { getArticle, getArticles } from "redux/slices/Articles";

import SEO from "service";
import HeadBanner from "assets/images/banner/webiner.png";
import Fb from "assets/icons/fb.png";
import Tw from "assets/icons/tw.png";
import Wa from "assets/images/wa.png";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import { ThreeDots } from "react-loader-spinner";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: cover;
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top center;
  }
`;

const ArticleContent = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 10px;
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 55vh;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  zindex: -1;
  border-radius: 50px;

  @media screen and (max-width: 430px) {
    height: 40vh;
  }
`;

const CardArticleBottom = styled.div`
  width: 95%;
  height: 180px;
  border-radius: 35px;
  background: #fff;
  padding: 20px;
  margin-top: -50px;
  margin-bottom: 30px;
  h5 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #000000;
  }

  @media screen and (max-width: 430px) {
    h5 {
      font-size: 22px;
      line-height: 35px;
    }
  }
`;

const Capsul = styled.div`
  width: 120px;
  height: 40px;
  background: #eeeb4b;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #0f7ca1;
  margin-bottom: 10px;

  @media (min-width: 430px) {
    width: 100px;
    height: 35px;
    font-size: 18px;
  }
`;

const HeadNewArticle = styled(CardHeader)`
  background: linear-gradient(90deg, #07a391 0%, #1078a3 100%);
  border: none;
  border-radius: initial !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;

  @media screen and (max-width: 430px) {
    font-size: 20px;
  }
`;

const ListGroupCustom = styled(Card)`
  border: 1px solid #98d5c4;
  border-radius: initial !important;
  min-height: 40vh;
`;

const ListGroupCustomTags = styled(Card)`
  border: 1px solid #98d5c4;
  border-radius: initial !important;
`;

const NewArticleTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-left: 20px;
  text-align: start;
  cursor: pointer;

  &:hover {
    color: #000000;
  }

  @media screen and (max-width: 430px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

const NewArticleTags = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-left: 20px;
  width: 70%;

  @media screen and (max-width: 430px) {
    font-size: 18px;
  }
`;

const TitleShare = styled.h5`
  font-weight: bold;
  margin-right: 20px;
  font-size: 32px;

  @media screen and (max-width: 430px) {
    font-size: 24px;
  }
`;

const ImageShare = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media screen and (max-width: 430px) {
    width: 25px;
    height: 25px;
  }
`;

const DetailsArticle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [carouselImageValue, setCarouselImageValue] = useState(HeadBanner);

  const { banners } = useSelector((state) => state.banners);
  const { articles, article, isLoadingArticle } = useSelector(
    (state) => state.articles
  );

  useEffect(() => {
    dispatch(getArticles());
    dispatch(getArticle(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (banners && banners.length !== 0) {
      const findData = banners.find((d) => d.name === "View Artikel");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  const handleConverUrl = (id, value) => {
    let url = "#";

    if (value) {
      let modifiedString = value.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
      modifiedString = modifiedString.replace(/\s+/g, "-");
      url = `${window.location.origin}/articles/details/${modifiedString}/${id}`;
    }
    return url;
  };

  const handleConverUrlArticle = (id, value) => {
    let url = "#";
    if (value) {
      let modifiedString = value.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
      modifiedString = modifiedString.replace(/\s+/g, "-");
      url = `/articles/details/${modifiedString}/${id}`;
    }
    window.location.replace(`${window.location.origin}${url}`);
  };

  return (
    <>
      <SEO
        title="Artikel Sahabat Alter Indonesia "
        description="Belajar bersama Alter dapat kan banyak pembelajaran yang akan membantu dalam masa perkuliahan."
        keywords="Fakultas kedokteran, Fakultas kedokteran Gigi, Ilmu Gizi, Kebidanan, Keprawatan, Ilmu Farmasi, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>
      {!isLoadingArticle && article && (
        <Row className="p-lg-5 p-md-5 p-3 container-fluid">
          <Col lg={12} className="mt-5">
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="d-flex flex-column justify-content-center align-items-center p-0 m-0">
                  <BackgroundImage image={`${API_URL}/${article.image}`} />
                  <CardArticleBottom>
                    <Capsul>
                      {moment(article.updated_at).format("DD MMM")}
                    </Capsul>
                    <h5 className="fw-bold text-dark">{article.title}</h5>
                  </CardArticleBottom>
                </div>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col lg={12}>
                    <Card className="border-0 h-100">
                      <HeadNewArticle>Artikel Terbaru</HeadNewArticle>
                      {isLoadingArticle ? (
                        <div
                          style={{
                            minHeight: "40vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#07a391"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <ListGroupCustom>
                          <ListGroup className="p-3">
                            {articles &&
                              articles.length > 0 &&
                              articles.slice(0, 3).map((data) => (
                                <ListGroupItem
                                  key={data.id}
                                  className="border-0"
                                >
                                  <div className="d-flex justify-content-start">
                                    <img
                                      src={`${API_URL}/${data.image}`}
                                      alt="john-schnobrich"
                                      width={78}
                                      height={78}
                                      style={{
                                        objectFit: "cover",
                                      }}
                                    />
                                    <NewArticleTitle
                                      // to={handleConverUrlArticle(
                                      //   data.id,
                                      //   data.title
                                      // )}
                                      onClick={() =>
                                        handleConverUrlArticle(
                                          data.id,
                                          data.title
                                        )
                                      }
                                    >
                                      {data.title}
                                    </NewArticleTitle>
                                  </div>
                                </ListGroupItem>
                              ))}
                          </ListGroup>
                        </ListGroupCustom>
                      )}
                    </Card>
                  </Col>
                  <Col lg={12} className="mt-3">
                    <Card className="border-0 h-100">
                      <HeadNewArticle>Tags:</HeadNewArticle>
                      <ListGroupCustomTags className="p-5">
                        <NewArticleTags>
                          {JSON.parse(article.tags).join(", ")}
                        </NewArticleTags>
                      </ListGroupCustomTags>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="d-flex flex-column mt-5">
            <ArticleContent
              dangerouslySetInnerHTML={{
                __html: article.content,
              }}
            />
          </Col>
          <Col lg={12} className="mt-3 d-flex align-items-center">
            <TitleShare>Bagikan:</TitleShare>
            <div>
              <FacebookShareButton
                url={handleConverUrl(article.id, article.title)}
                title={article.title}
              >
                <ImageShare src={Fb} alt="facebook" />
              </FacebookShareButton>
              <TwitterShareButton
                url={handleConverUrl(article.id, article.title)}
                title={article.title}
              >
                <ImageShare src={Tw} alt="Twitter" />
              </TwitterShareButton>
              <WhatsappShareButton
                url={handleConverUrl(article.id, article.title)}
                title={article.title}
              >
                <ImageShare src={Wa} alt="whatsapp" />
              </WhatsappShareButton>
            </div>
          </Col>
        </Row>
      )}

      <FooterCustom />
    </>
  );
};

export default DetailsArticle;
