// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, Container } from "@mui/material";
import styledC from "styled-components";
import LoginForm from "./LoginForm";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "50vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 0),
}));

const Title = styledC.h1`
  font-weight: 700;
  font-size: 28px;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

// ----------------------------------------------------------------------

export default function Login(props) {
  return (
    <Box>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Title>Sign in to Alter</Title>
              </Box>
            </Stack>
            <LoginForm setModalLogin={props.setModalLogin} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Box>
  );
}
