import React from 'react';
import { Box } from '@mui/material';
import { Link } from "react-router-dom";
import LogoImage from "assets/images/alter.png";

const Logo= (props) => {
  return (
    <Box 
      component={Link} 
      to="/" 
      onClick={() => {
        props.setPosition("")
        props.setVisibleMenu(false)
      }}
    >
      <img alt="logo" src={LogoImage} width={100} height={45} />
    </Box>
  )
}

export default Logo
