import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "helpers/GatewayService";

// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Divider,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "./Iconify";
import FormProvider from "./FormProvider";
import SocialsButton from "./SocialsButton";
import { URL_FE } from "helpers/ENVState";
import styled from "styled-components";

const StyledButtonCustom = styled(LoadingButton)`
  height: fit-content;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%)
      padding-box,
    linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%) border-box;
  border-radius: 50em;
  text-align: center;
  text-decoration: none;
  border: 2px solid transparent;
  margin-bottom: 5px;
  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    align-items: center;
    text-align: center;
  }

  :hover {
    color: #ffffff;
  }
`;

const StyledButton = styled(LoadingButton)`
  cursor: pointer;
  height: fit-content;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%) border-box !important;
  border-radius: 50em;
  border: 2px solid transparent !important;
  text-align: center;
  text-decoration: none;
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    background: -webkit-linear-gradient(
      80.87deg,
      #05a390 16.49%,
      #1170a6 103.22%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export default function LoginForm(props) {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(search);

  useEffect(() => {
    const token = query.get("secret");
    if (token) {
      handleLoginGoogle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleLoginGoogle = async () => {
    const token = query.get("secret");
    const user = {
      first_name: query.get("first") ? query.get("first") : "",
      last_name: query.get("last") ? query.get("last") : "",
      image: query.get("image") ? query.get("image") : "",
      role: query.get("role") ? query.get("role") : "",
      univ: query.get("univ") ? query.get("univ") : "",
    };
    if (token && user) {
      const session = {
        token,
        ...user,
      };
      await Cookies.set("user", JSON.stringify(session), {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 30,
      });
      window.location.replace("/dashboard/app");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email tidak valid")
      .required("Email harus diisi"),
    password: Yup.string().required("Password harus diisi"),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.post(API_URL + "/api/login", data);

    if (response.data.status === "success") {
      const { accessToken, user } = response.data.result;

      const session = {
        token: accessToken,
        user,
      };
      Cookies.set("user", JSON.stringify(session), {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 30,
      });

      props.setModalLogin(false);
    }
    if (response.data.status === "failed") {
      setError("afterSubmit", { message: response.data.message });
    }
    setLoading(false);
  };

  const handleDaftar = () => {
    window.location.replace(`${`${URL_FE}/auth/register`}`);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              ref={field.ref}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              fullWidth
              error={!!error}
              helperText={error?.message}
              label="Email"
              name="email"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              ref={field.ref}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              fullWidth
              error={!!error}
              helperText={error?.message}
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link> */}
      </Stack>

      <StyledButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{
          mb: 1,
        }}
      >
        <span>Masuk</span>
      </StyledButton>

      <StyledButtonCustom
        fullWidth
        size="large"
        type="button"
        variant="contained"
        onClick={handleDaftar}
        loading={false}
      >
        <span>Daftar</span>
      </StyledButtonCustom>

      <Divider sx={{ mt: 5 }}>OR</Divider>

      <Stack alignItems="center">
        <SocialsButton
          initialColor
          sx={{ mt: 2.5 }}
          links={{
            google: `${process.env.REACT_APP_API_URL}/api/login/google`,
          }}
        />
      </Stack>
    </FormProvider>
  );
}
