// utils
import createAvatar from "./createAvatar";
//
import Avatar from "./Avatar";
import { API_URL } from "helpers/ENVState";
import Cookies from "js-cookie";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const user = Cookies.get("user");
  const data = user ? JSON.parse(user) : {};

  return (
    user && (
      <Avatar
        src={`${API_URL}/${data.user.image ? data.user.image : ""}`}
        alt={user?.name}
        color={
          data?.user?.image ? "default" : createAvatar(data.user?.name).color
        }
        {...other}
      >
        {createAvatar(user?.name).name}
      </Avatar>
    )
  );
}
