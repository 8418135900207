import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
// @mui
import {
  Box,
  Card,
  Table,
  TableBody,
  IconButton,
  TableContainer,
  TextField,
  InputAdornment,
  Grid
} from '@mui/material';
import Iconify from "components/organisms/ModalLogin/Components/Iconify";

import { API_URL } from "helpers/ENVState";
import { getAllEbooks } from "redux/slices/Ebooks";
import CarouselImage from "assets/images/banner/header-banner-about.png";
import Scrollbar from 'components/atoms/Scrollbar';
import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import TableHeadCustom from './TableHeadCustom';
import EbookTableRow from './EbookTableRow';
import TableSkeleton from './TableSkeleton';
import TableNoData from './TableNoData';

import {
  Banner,
  Title,
  Note,
} from "./StudiesStyle";

import PostContent from "./EbookCard";

const TABLE_HEAD = [
  { id: 'name', label: 'Nama', align: 'left' },
  { id: 'author', label: 'Nama Penulis', align: 'left' },
  { id: 'tahun_terbit', label: 'Tahun Terbit', align: 'left' },
  { id: 'penerbit', label: 'Penerbit', align: 'left' },
];


const Studies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allEbooks, setAllEbooks] = useState([]);
  const [position, setPosition] = useState("grid");
  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);
  const [filterName, onFilterName] = useState("");

  const { ebooks, isLoadingEbooks } = useSelector((state) => state.ebooks);
  const { banners } = useSelector((state) => state.banners);

  useEffect(() => {
    dispatch(getAllEbooks());
  }, [dispatch]);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Perpustakaan Digital");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (!isLoadingEbooks && ebooks.length !== 0) {
      let temp = [];
      ebooks.forEach((element) => {
        temp.push({
          id: element.id,
          name: element.name,
          author: element.author,
          image: `${API_URL}/${element.picture}`,
          tahun_terbit: element.tahun_terbit,
          penerbit: element.penerbit,
        });
      });
      setAllEbooks([...temp]);
    }
  }, [isLoadingEbooks, ebooks]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filterName === "") {
      let temp = [];
      ebooks.forEach((element) => {
        temp.push({
          id: element.id,
          name: element.name,
          author: element.author,
          image: `${API_URL}/${element.picture}`,
          tahun_terbit: element.tahun_terbit,
          penerbit: element.penerbit,
        });
      });
      setAllEbooks(temp);
    }

    if (filterName !== "") {
      let temp = [];
      temp = allEbooks.filter(
        (item) =>
          item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      );
      setAllEbooks(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName]);

  const isNotFound = (!isLoadingEbooks && !ebooks.length);

  const handleNavigate = (data) => {
    if (data) {
      let modifiedString = data.name
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
      modifiedString = modifiedString.replace(/\s+/g, "-");
      return navigate(`/ebooks/details/${modifiedString}/${data.id}`);
    }
  };

  return (
    <>
      <SEO
        title="Perpustakaan Digital Sahabat Alter Indonesia"
        description="Alter merupakan aplikasi pembelajaran yang berisi materi perkuliahan kesehatan dalam bentuk teks dan video yang interaktif."
        keywords="Kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi, Ilmu Gizi, Sahabat Alter, PT Sahabat Alter Indonesia, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid />

      {/* Studies */}
      <Container className="my-5">
        <Row className="gap-3">
          <Col
            lg={12}
            className="d-flex flex-column justify-content-center align-items-center "
          >
            <Title>Perpustakaan Digital</Title>
            <Note>Pilih buku yang kamu minati</Note>
          </Col>
          <Col
            lg={12}
            className="d-flex justify-content-end align-items-center gap-2"
          >
            <TextField
              value={filterName}
              onChange={(event) => onFilterName(event.target.value)}
              placeholder="Search perpustakaan digital..."
              sx={{
                width: {
                  xs: "100%",
                  md: "40%",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={'eva:search-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              onClick={() => setPosition("list")}
              edge="end"
            >
              <Iconify
                icon={"nimbus:list"}
                sx={{
                  color: position === "list" ? "#05a390" : "#6e6e6e",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => setPosition("grid")}
              edge="end"
              sx={{
                color: position === "grid" ? "#05a390" : "#6e6e6e",
              }}
            >
              <Iconify
                icon={"bi:grid"}
              />
            </IconButton>

          </Col>
          {position === "grid" && (
            <Col lg={12} className="mt-5 px-4 px-sm-0">
              <Grid container spacing={3}>
              {allEbooks.map((data) => (
                  <Grid
                    key={data.id}
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    onClick={() => handleNavigate(data)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <PostContent post={data} />
                  </Grid>
                ))}
              </Grid>
            </Col>
          )}
          {position === "list" && (
            <Col lg={12} className="mt-5">
              <Card sx={{ borderRadius: '8px' }}>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 960, position: 'relative', border: 'none' }}>
                    <Table sx={{ border: 'none' }}>
                      <TableHeadCustom headLabel={TABLE_HEAD} />

                      <TableBody sx={{ border: 'none' }}>
                        {(isLoadingEbooks ? [...Array(3)] : allEbooks)
                          .map((row, index) =>
                            row ? (
                              <EbookTableRow
                                key={row.id}
                                row={row}
                              />
                            ) : (
                              !isNotFound && <TableSkeleton key={index} />
                            )
                          )}

                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <Box sx={{ position: 'relative' }} />
              </Card>
            </Col>
          )}
        </Row>
      </Container>

      <FooterCustom section={true} />
    </>
  );
};

export default Studies;
