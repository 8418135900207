import React, { useEffect, useState } from "react";
import { Col, Container, Input, Row, Form } from "reactstrap";
import { send } from "emailjs-com";
import { useSelector } from "react-redux";

import { API_URL } from "helpers/ENVState";

import SEO from "service";
import styled from "styled-components";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import CarouselImage from "assets/images/head-banner-mobile.png";
import "components/organisms/Layouts/Footer.css";

import { toast } from "react-toastify";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: cover
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top -10px center;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 64px;
  line-height: 96px;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 840px) {
    font-size: 52px;
    line-height: 52px;
  }

  @media screen and (max-width: 430px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media screen and (max-width: 380px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const ButtonKirim = styled.button`
  width: 228px;
  height: 60px;
  background: #eeeb4b;
  border-radius: 10px;
  border: none;
  margin: 0 30px;

  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  color: #0f7ca1;

  @media screen and (max-width: 430px) {
    width: 180px;
    height: 40px;
    font-size: 18px;
  }
`;

const Note = styled.span`
  width: 300px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #000000;

  @media screen and (max-width: 840px) {
    width: 60%;
    font-size: 22px;
  }

  @media screen and (max-width: 430px) {
    width: 90%;
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (max-width: 380px) {
    width: 80%;
    font-size: 18px;
  }
`;

const CustomInput = styled(Input)`
  height: 67px;
  background: #29839f;
  border-radius: 15px;
  width: 100%;
  color: #fff;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d0d0ce;
    opacity: 1; /* Firefox */
    font-size: 14px;
    padding-left: 10px;
  }
  :focus {
    background: #29839f;
    color: white;
    font-size: 16px;
  }
`;

const CustomTextArea = styled(Input)`
  max-height: 100px;
  background: #29839f;
  border-radius: 15px;
  width: 100%;
  color: #fff;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d0d0ce;
    opacity: 1; /* Firefox */
    font-size: 14px;
    padding: 10px 0 0 10px;
  }
  :focus {
    background: #29839f;
    color: white;
    font-size: 16px;
  }
`;

const Contact = () => {
  const { banners } = useSelector((state) => state.banners);

  const [toSend, setToSend] = useState({
    name: "",
    lastName: "",
    email: "",
    telp: "",
    message: "",
  });
  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Kontak");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (toSend.telp === "" && toSend.message === "") {
      toast.error("Isikan Nomor Telephone dan Pesan !");
    }

    if (toSend.telp !== "" && toSend.message !== "") {
      const payload = {
        from_fristname: toSend.name,
        from_lastname: toSend.lastName,
        to_name: "Alter Indonesia team's",
        from_firstname: toSend.name,
        form_telp: toSend.telp,
        reply_to: toSend.email,
        message: toSend.message,
      };
      send("service_aqw1cbt", "template_2feykyg", payload, "UvJM9-_5rhjmqN719")
        .then((response) => {
          toast.success("Pesan Terkirim");
          setToSend({
            name: "",
            lastName: "",
            email: "",
            telp: "",
            message: "",
          });
        })
        .catch((err) => {
          toast.error("Maaf System Sedang Error");
        });
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <>
      <SEO
        title="Hubungi Sahabat Alter Indonesia "
        description="PT Sahabat Alter Indonesia penyedia pembelajaran ilmu kesehatan untuk fakultas Kedokteran, Fakultas Kedokteran Gigi, Kebidanan, Ilmu Gizi dan Keprawatan."
        keywords="PT Sahabat Alter Indonesia, Ilmu Kedokteran, Ilmu Kedokteran Gigi, Ilmu Kebidanan, Ilmu Gizi, Ilmu Farmasi, belajar kebidanan, Ukom perawat, ukom bidan, ukom gizi, ukom farmasi"
        type="article"
      />
      <Banner backgroundimage={carouselImageValue} fluid></Banner>

      <Container className="my-5">
        <Row>
          <Col
            lg={12}
            className="d-flex justify-content-center flex-column align-items-center mt-5"
          >
            <Title>Kirim Pesan Kepada Kami</Title>
            <Note>
              Kami berkomitmen untuk melayani dengan layanan yang luar biasa
            </Note>
          </Col>

          <Col
            lg={12}
            className="d-flex justify-content-center flex-column align-items-center mt-5"
          >
            <Form onSubmit={onSubmit}>
              <Row className="w-100">
                <Col lg={6}>
                  <CustomInput
                    type="text"
                    placeholder="Nama Depan"
                    name="name"
                    value={toSend.name}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg={6} className="mt-lg-0 mt-md-5 mt-5">
                  <CustomInput
                    type="text"
                    name="lastName"
                    value={toSend.lastName}
                    onChange={handleChange}
                    placeholder="Nama Belakang"
                  />
                </Col>
                <Col lg={6} className="mt-5">
                  <CustomInput
                    type="email"
                    name="email"
                    value={toSend.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </Col>
                <Col lg={6} className="mt-5">
                  <CustomInput
                    type="number"
                    name="telp"
                    value={toSend.telp}
                    onChange={handleChange}
                    placeholder="No Handphone"
                  />
                </Col>
                <Col lg={12} className="mt-5">
                  <CustomTextArea
                    placeholder="Pesan"
                    type="textarea"
                    name="message"
                    value={toSend.message}
                    onChange={handleChange}
                  />
                </Col>
                <Col
                  lg={12}
                  className="mt-5 d-flex justify-content-center align-items-center"
                >
                  <ButtonKirim type="submit" onClick={onSubmit}>
                    Kirim Pesan
                  </ButtonKirim>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col
            lg={12}
            className="d-flex justify-content-center flex-column align-items-center mt-5"
          >
            <iframe
              title="Sahabat Alter Indonesia"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.7059330005854!2d106.74594999392953!3d-6.2173902574417355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f763d0fa24a3%3A0x620593a8fa09003!2sPT%20Sahabat%20Alter%20Indonesia!5e0!3m2!1sen!2sid!4v1667751098039!5m2!1sen!2sid"
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </Col>
        </Row>
      </Container>

      <FooterCustom section={true} />
    </>
  );
};

export default Contact;
