import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Menu, Close } from "@mui/icons-material";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import AuthNavigation from "./AuthNavigation";
import { DataNavigations } from "./DataNavigation";

import { getAllBanners } from "redux/slices/Banner";

import Logo from "components/atoms/Logo";
import ModalLogin from "../ModalLogin";
import Cookies from "js-cookie";

import AppBar from "@mui/material/AppBar";
import { Stack } from "@mui/material";
import AccountPopover from "./AccountPopover";

const NavbarCustom = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userCheck = Cookies.get("user");
  const data = userCheck ? JSON.parse(userCheck) : {};

  const { banners } = useSelector((state) => state.banners);

  const [modalLogin, setModalLogin] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [position, setPosition] = useState("");
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));

  useEffect(() => {
    dispatch(getAllBanners());
    if (banners.length === 0) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setPosition(location.pathname.replace(/[^\w\s]/gi, ""));
  });

  const toggleModalLogin = () => setModalLogin(!modalLogin);

  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar
            sx={{
              backgroundColor: "background.paper",
              // boxShadow: "rgb(12 40 70 / 10%) 0px 2px 12px 0px",
              px: {
                md: 2,
                xs: 2,
              },
            }}
            // className="sticky-top"
          >
            <Container sx={{ py: { xs: 2, md: 2 }, px: 0, maxWidth: { md: '1300px' } }} fluid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "transparent",
                }}
              >
                {/* Icon Menu mobile */}
                {userCheck && (
                  <Box
                    sx={{
                      display: { xs: "inline-flex", md: "none" },
                    }}
                    onClick={() => setVisibleMenu(!visibleMenu)}
                  >
                    {visibleMenu && matchMobileView ? (
                      <IconButton>
                        <Close />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <Menu />
                      </IconButton>
                    )}
                  </Box>
                )}

                <Logo setPosition={setPosition} setVisibleMenu={setVisibleMenu} />

                <Box
                  sx={{
                    ml: "auto",
                    display: { xs: "inline-flex", md: "none" },
                  }}
                  onClick={() => {
                    if (!userCheck) {
                      setVisibleMenu(!visibleMenu);
                    }
                  }}
                >
                  {userCheck ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={{ xs: 0.5, sm: 1.5 }}
                    >
                      <AccountPopover />
                    </Stack>
                  ) : visibleMenu && matchMobileView ? (
                    <IconButton>
                      <Close />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <Menu />
                    </IconButton>
                  )}
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // use this active login
                    // justifyContent: "start",
                    pl: { md: 5 },
                    flexDirection: { xs: "column", md: "row" },
                    transition: (theme) => theme.transitions.create(["top"]),
                    ...(matchMobileView && {
                      py: 5,
                      pl: 2,
                      backgroundColor: "background.paper",
                      zIndex: "appBar",
                      position: "fixed",
                      height: { xs: "100vh", md: "auto" },
                      top: visibleMenu ? 60 : "-120vh",
                      display: visibleMenu ? "block" : "none",
                      left: 0,
                    }),
                  }}
                >
                  <Box /> {/* Magic space */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    {DataNavigations.map(
                      ({ path: destination, label, value }) => (
                        <Box
                          component={Link}
                          key={destination}
                          activeclass="current"
                          to={destination}
                          spy="true"
                          smooth="true"
                          duration={350}
                          onClick={() => {
                            setVisibleMenu(!visibleMenu);
                            setPosition(value);
                          }}
                          sx={{
                            position: "relative",
                            color: "text.disabled",
                            cursor: "pointer",
                            fontWeight: 600,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: { xs: "start", md: "center" },
                            textDecoration: "none",
                            px: { xs: 0, md: 3 },
                            mb: { xs: 3, md: 0 },
                            fontSize: { xs: "1.2rem", md: "inherit" },
                            ...(destination === "/" && {
                              color: "primary.main",
                            }),
                            ...(position.includes(value) && {
                              background:
                                "linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%)",
                              backgroundClip: "text",
                              textFillColor: "transparent",
                              webkitBackgroundClip: "text",
                              webkitTextFillColor: "transparent",
                            }),
                            "& > div": { display: "none" },

                            "&.current>div": { display: "block" },

                            "&:hover": {
                              background:
                                "linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%)",
                              backgroundClip: "text",
                              textFillColor: "transparent",
                              webkitBackgroundClip: "text",
                              webkitTextFillColor: "transparent",
                              "&>div": {
                                display: "block",
                              },
                            },
                          }}
                        >
                          {label}
                        </Box>
                      )
                    )}
                  </Box>
                  {userCheck ? (
                    !matchMobileView && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={{ xs: 0.5, sm: 1.5 }}
                      >
                        <span
                          style={{
                            margin: 0,
                            color: "#34364a",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          Hai, {data.user.first_name}
                        </span>
                        <AccountPopover />
                      </Stack>
                    )
                  ) : (
                    <AuthNavigation toggleModalLogin={toggleModalLogin} />
                  )}
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <ModalLogin
        modalLogin={modalLogin}
        setModalLogin={setModalLogin}
        toggleModalLogin={toggleModalLogin}
      />
    </React.Fragment>
  );
};

export default NavbarCustom;
