import React from "react";
import { Modal, ModalBody } from "reactstrap";

import LoginForm from "./Components/ContentLogin";
const ModalLogin = (props) => {
  return (
    <Modal
      isOpen={props.modalLogin}
      toggle={props.toggleModalLogin}
      centered
      size="md"
    >
      <ModalBody className="p-2 border-0">
        <LoginForm
          setModalLogin={props.setModalLogin}
          handleSubmit={props.handleSubmit}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalLogin;
