/* eslint-disable no-self-compare */
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";

// @mui
import { alpha } from "@mui/material/styles";
import { Divider, Stack, MenuItem } from "@mui/material";

// components
import MyAvatar from "components/organisms/MyAvatar";
import MenuPopover from "./MenuPopover";

import IconButtonAnimate from "./IconButtonAnimate";
import { URL_FE } from "helpers/ENVState";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (key) => {
    if (key === "profile") {
      window.location.replace(`${URL_FE}/dashboard/user/profile`);
    }
    if (key === "kelas") {
      window.location.replace(`${URL_FE}/dashboard/kelas-saya/list`);
    }
    setOpen(null);
  };

  const handleLogout = async () => {
    await Cookies.remove("user", {
      domain: process.env.REACT_APP_DOMAIN,
    });
    window.location.replace("/");
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem
            key="profile"
            to="#"
            component={RouterLink}
            onClick={() => handleClose("profile")}
          >
            Profile
          </MenuItem>
          <MenuItem
            key="courser"
            to="#"
            component={RouterLink}
            onClick={() => handleClose("kelas")}
          >
            My Courses
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
