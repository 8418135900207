import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { API_URL } from "helpers/ENVState";

import FooterCustom from "components/organisms/Layouts/FooterCustom";
import AboutLeft from "assets/images/about/about-left.png";
import AboutRight from "assets/images/about/about-right.png";
import ChooseUs from "assets/images/about/choose-us.png";
import CarouselImage from "assets/images/banner/header-banner-about.png";
import SEO from "service";

import {
  Banner,
  Note,
  SectionCustom,
  TitleInfo,
  TitleContainer
} from "./AboutStyle";

const About = () => {
  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);

  const { banners } = useSelector((state) => state.banners);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Tentang Kami");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  return (
    <>
      <SEO
        title="Tentang Sahabat Alter Indonesia"
        description="Alter Indonesia bekerja sama dengan berbagai Institusi Pendidikan, Akademisi serta Praktisi hadir untuk memberikan pembelajaran yang mudah serta berkualitas untuk memajukan Pendidikan dan Kesehatan di Indonesia."
        keywords="Alter, Sahabat Alter, aplikasi kedokteran, kedokteran terbaik di indonesia, materi kedokteran umum, Materi Kedokteran Gigi, ukom perawat, ukom bidan, ukom gizi, ukom farmasi"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>
      <section className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
        {/* Tentang Alter */}
        <section className="xl:tw-w-[1340px] tw-w-full xl:tw-px-0 tw-px-5 tw-flex tw-flex-col xl:tw-flex-row tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-w-full xl:tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-5 tw-flex tw-flex-col">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <h1 className="tw-font-semibold tw-text-xl tw-text-black">Tentang Alter</h1>
              <Note className="tw-font-bold xl:tw-text-4xl xl:tw-leading-[43px] tw-text-[28px] tw-leading-8">Teknologi di era saat ini menjadi kebutuhan bagi kita semua</Note>
              <img src={AboutLeft} className="tw-w-full md:tw-h-[300px] xl:tw-h-auto tw-rounded-xl tw-h-[250px] " alt="team" />
            </div>
            <div className=" tw-flex tw-flex-col tw-gap-2">
              <img src={AboutRight} alt="amy hirschi" className="tw-w-full tw-hidden xl:tw-block tw-h-auto tw-rounded-xl" />
              <div className="tw-w-full tw-flex tw-flex-col tw-gap-1">
                <h6 className="tw-text-black tw-text-2xl tw-font-medium tw-text-end">Alter Berdiri Pada Tahun</h6>
                <h3 className="tw-font-bold tw-text-8xl tw-text-end tw-text-black">2018</h3>
              </div>
            </div>
            <div className="tw-col-span-2 tw-text-justify">
              <span className="md:tw-text-xl tw-text-lg">
                Perlu adanya suatu terobosan di bidang pendidikan yang mampu membawa pembelajaran menjadi tanpa batas ruang dan waktu. Alter Indonesia bekerja sama dengan berbagai Institusi Pendidikan, Akademisi serta Praktisi hadir untuk memberikan pembelajaran yang mudah serta berkualitas untuk memajukan Pendidikan dan Kesehatan di Indonesia.
              </span>
            </div>
          </div>
        </section>

        {/* Visi Misi */}
        <section className="xl:tw-w-[1340px] tw-w-full xl:tw-px-0 tw-px-5 tw-grid xl:tw-grid-cols-2 tw-grid-cols-1 tw-gap-5 md:tw-py-[80px] tw-py-[40px]">
          <SectionCustom className="tw-flex tw-flex-col tw-items-center tw-p-3 xl:tw-p-5">
            <TitleInfo>Visi</TitleInfo>
            <span className="tw-text-white tw-font-medium tw-text-2xl tw-text-center">
              “Memberikan kemudahan akses pembelajaran yang berkualitas kepada
              Institusi Pendidikan khususnya di bidang Kesehatan sehingga
              dapat memajukan Pendidikan dan Kesehatan di Indonesia”
            </span>
          </SectionCustom>
          <SectionCustom className="tw-flex tw-flex-col tw-items-center tw-p-3 xl:tw-p-5">
            <TitleInfo>Misi</TitleInfo>
            <div className="tw-w-full tw-text-white">
              <ul className="tw-flex tw-flex-col tw-gap-3 tw-pl-0">
                <li className="tw-text-justify tw-font-medium tw-text-xl">
                  Menjadi wadah berbagi ilmu pengetahuan bagi pengajar kepada
                  mahasiswa agar akses pembelajaran secara mandiri lebih
                  mudah.
                </li>
                <li className="tw-text-justify tw-font-medium tw-text-xl">
                  Menjalin kerja sama dengan berbagai Institusi Pendidikan
                  agar saling membangun.
                </li>
                <li className="tw-text-justify tw-font-medium tw-text-xl">
                  Memberikan informasi terkait dengan pemanfaatan dan
                  penggunaan aplikasi Alter Indonesia kepada seluruh Institusi
                  Pendidikan Kesehatan.
                </li>
                <li className="tw-text-justify tw-font-medium tw-text-xl">
                  Menyediakan sebuah komunitas mahasiswa kesehatan yang
                  bersifat saling mendukung serta memberikan inovasi dalam
                  dunia pendidikan.
                </li>
              </ul>
            </div>
          </SectionCustom>
        </section>

        {/* Choose us */}
        <section className="xl:tw-w-[1340px] tw-w-full xl:tw-px-0 tw-px-5 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
            <TitleContainer>Kenapa Memilih Kami</TitleContainer>
          </div>
          <div className="tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-items-center tw-gap-5">
            <div className="tw-w-full">
              <img src={ChooseUs} alt="christina-wocintechchat" className="tw-rounded-xl tw-w-full" />
            </div>
            <div className="tw-w-full tw-text-black">
              <ol className="tw-flex tw-flex-col tw-gap-3 tw-list-decimal">
                <li className="tw-text-justify tw-font-medium tw-text-2xl">
                  Materi yang diberikan oleh pengajar Alter meliputi
                  akademisi dan praktisi yang profesional serta ahli
                  dibidangnya.
                </li>
                <li className="tw-text-justify tw-font-medium tw-text-2xl">
                  Lengkap dan terbaru sesuai dengan kurikulum pembelajaran
                  di kampus.
                </li>
                <li className="tw-text-justify tw-font-medium tw-text-2xl">Akses jurnal ilmiah terbaru.</li>
                <li className="tw-text-justify tw-font-medium tw-text-2xl">Dapat diakses dimana dan kapan saja.</li>
                <li className="tw-text-justify tw-font-medium tw-text-2xl">Paperless (praktis dan ramah lingkungan).</li>
                <li className="tw-text-justify tw-font-medium tw-text-2xl">
                  Tidak perlu berlangganan (cukup bayar 1x akses
                  seterusnya).
                </li>
              </ol>
            </div>
          </div>
        </section>
      </section>
      <FooterCustom />
    </>
  );
};

export default About;
