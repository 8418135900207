/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { API_URL } from "helpers/ENVState";
import { getTestimonials } from "redux/slices/Testimonials";
import { getAllMateri } from "redux/slices/Materi";

import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import FaqComponents from "components/atoms/Accordions"
import EmptyContent from "components/organisms/EmptyContent";
import InfiniteScrollComponent from "./Components/ClientUniv";
import InfiniteScrollTesti from "./Components/Testimony";
import { DataCorporateValues } from "./Components/Datas";
import VideoMateri from "./Components/VideoMateri";

import Dots from "assets/icons/dots.svg";
import DotsOrange from "assets/icons/dots-orange.svg";
import DotsGreen from "assets/icons/dots-green.svg";

// Our Services
import KelasDigital from "assets/images/home/services/kelas-digital.png";
import LiveTeaching from "assets/images/home/services/live-teaching.png";
import SimulasiUkom from "assets/images/home/services/simulasi-ukom.png";
import PerpustakaanDigital from "assets/images/home/services/perpustakaan-digital.png";

// Our Facilities
import Elibrary from "assets/images/home/facilities/e-library.webp";
import Ejournal from "assets/images/home/facilities/e-journal.webp";

// Banner Event
import BannerManagement from "assets/images/home/ads/banner-event-management.webp";


const CardDataService = [
  {
    id: 1,
    no: "01",
    key: 'normal',
    name: "Kelas Digital",
    image: KelasDigital,
    note: "Kelas digital merujuk pada pembelajaran yang dilakukan secara online melalui platform digital yang diakses melalui video ekslusif.",
  },
  {
    id: 2,
    no: "02",
    key: 'italic',
    name: "Live Teaching",
    image: LiveTeaching,
    note: "Proses pembelajaran di mana pengajar mengajar langsung kepada siswa secara real-time platform digital melalui platform konferensi secara daring.",
  },
  {
    id: 3,
    no: "03",
    key: 'normal',
    name: "Simulasi Ukom",
    image: SimulasiUkom,
    note: "Proses di mana seseorang melakukan latihan simulasi yang menyerupai ujian yang diperlukan dan dilengkapi dengan video pembahasannnya untuk menyiapkan uji kompetensi.",
  },
  {
    id: 4,
    no: "04",
    key: 'normal',
    name: "Perpustakaan Digital",
    image: PerpustakaanDigital,
    note: "Kumpulan <i>ebook</i> yang dapat diakses melalui perangkat digital seperti laptop, tablet, atau smartphone. Dengan tambahan audio book, pengguna dapat menikmati buku dalam format audio, memperluas aksesibilitas dan kenyamanan. Ini mengubah perpustakaan menjadi pusat pembelajaran inklusif yang modern.",
  },
];

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [carouselImageValue, setCarouselImageValue] = useState([]);
  const [selectedServices, setSelectedServices] = useState(1);
  const [selectedServiceImg, setSelectedServiceImg] = useState(KelasDigital);
  const [allMateri, setAllMateri] = useState([]);

  const { banners } = useSelector((state) => state.banners);
  const { materis, isLoadingMateri } = useSelector((state) => state.materis);

  useEffect(() => {
    dispatch(getTestimonials());
    dispatch(getAllMateri());
  }, [dispatch]);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Home");
      if (findData) {
        let temp = [];
        findData.banners.forEach((d) => {
          temp.push({
            id: d.id,
            link: d.link,
            image: `${API_URL}/${d.image}`
          });
        })
        setCarouselImageValue(temp);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (!isLoadingMateri && materis.length !== 0) {
      let temp = [];
      materis.forEach((element) => {
        temp.push({
          id: element.id,
          name: element.name,
          type: element.type,
          image: `${API_URL}/${element.picture}`,
        });
      });
      setAllMateri([...temp]);
    }
  }, [isLoadingMateri, materis]);

  const handleConverUrl = (data) => {
    let url = "#";
    if (data) {
      let modifiedString = data.name
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
      modifiedString = modifiedString.replace(/\s+/g, "-");
      if (data.type === "materi") {
        url = `/studies/details/${modifiedString}/${data.id}`;
      }
      if (data.type === "ukom") {
        url = `/ukom/details/${modifiedString}/${data.id}`;
      }
    }
    navigate(url);
  };

  return (
    <Fragment>
      <SEO
        title="Sahabat Alter Indonesia"
        description="PT Sahabat Alter Indonesia adalah penyedia layanan pembelajaran ilmu kesahatan berbasis digital"
        name="Alter Indonesia"
        keywords="Alter, Sahabat Alter, aplikasi kedokteran, Alter kedokteran, Alter Kebidanan, Alter Keprawatan, Alter Gizi, Sahabat Alter Indonesia"
        type="article"
      />

      {carouselImageValue.length > 0 && (
        <Swiper
          modules={[Navigation]}
          navigation
          loop
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{

          }}
        >
          {carouselImageValue.map((d) => (
            <SwiperSlide>
              <img
                src={d.image}
                alt=""
                className="tw-w-full xl:tw-h-screen tw-h-full tw-cursor-pointer" 
                onClick={() => {
                  window.open(d.link, '_blank');
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <section className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center xl:tw-pt-[100px] tw-pt-[50px]">
        {/* About */}
        {/* <div className="xl:tw-w-[1280px] tw-w-full xl:tw-px-0 tw-px-10 tw-flex tw-flex-col xl:tw-flex-row tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]"> */}
        <div className="xl:tw-w-[1280px] tw-w-full xl:tw-px-0 tw-px-10 tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-items-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-relative tw-w-full tw-h-auto ">
            <div className="tw-absolute tw-top-[-40px] tw-left-[-40px] tw-z-[-1]">
              <img src={Dots} alt="dots" />
            </div>
            <VideoMateri />
          </div>
          <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <h1 className="tw-text-[#1D2B4F] md:tw-text-[64px] tw-text-4xl tw-font-bold md:tw-leading-[88px]">Alter Indonesia</h1>
            <span className="xl:tw-text-lg tw-text-xl tw-text-justify">Alter Indonesia merupakan platform pembelajaran digital yang berisi kelas digital, kegiatan secara daring dan soal-soal simulasi uji kompetensi bagi mahasiswa/i Kesehatan yang dilengkapi dengan pembahasan dalam bentuk tertulis maupun video.</span>
            <span className="xl:tw-text-lg tw-text-xl tw-text-justify">Kumpulan soal dan pembahasan Alter disusun dan direview oleh tim pengajar yang terseleksi dengan baik serta berkompeten dalam berbagai bidang kesehatan.</span>
            <span className="xl:tw-text-lg tw-text-xl tw-text-justify">Kami berkomitmen untuk membantu memudahkan mahasiswa belajar agar lulus uji kompetensi dengan nilai yang memuaskan.</span>
            <div className="tw-flex tw-items-center tw-justify-center tw-pt-3">
              <button onClick={() => { navigate('/about') }} className="tw-w-[220px] tw-h-[60px] tw-bg-gradient-to-b tw-from-[#3BE4CE] tw-to-[#169E8C] tw-rounded-lg">
                <span className="tw-text-white tw-font-semibold">Lihat Selengkapnya</span>
              </button>
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="xl:tw-w-[1280px] tw-w-full tw-px-10 xl:tw-px-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-w-full tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-10">
            <div className="tw-w-full tw-flex tw-flex-col">
              <h2 className="tw-text-[#1D2B4F] md:tw-text-5xl tw-text-4xl xl:tw-leading-[60.34px] tw-font-bold tw-italic">Lets Go Digital</h2>
              <span className="tw-text-[#1D2B4F] md:tw-text-4xl tw-text-2xl xl:tw-leading-[46.51px] tw-text-normal">Kompeten dengan Cara Baru</span>
            </div>
            <div className="tw-w-full tw-text-justify tw-flex tw-justify-center tw-items-center">
              <span className="tw-text-[#798196] xl:tw-text-base tw-text-lg tw-leading-7 tw-text-normal">Metode yang kami kembangkan guna menggantikan proses manual dengan proses otomatisasi atau digital, meningkatkan aksesibilitas melalui platform online. Hal ini mencakup pembelajaran secara digital, pengembangan keterampilan digital, atau bahkan perubahan paradigma dalam cara berpikir atau bekerja.</span>
            </div>
          </div>
          <div className="tw-w-full tw-flex tw-flex-col tw-gap-5 xl:tw-flex-row tw-justify-between">
            <div className="xl:tw-w-[400px] tw-w-full xl:tw-flex xl:tw-flex-col md:tw-grid md:tw-grid-cols-2 tw-gap-3 tw-justify-between tw-overflow-x-auto tw-flex">
              {CardDataService.map((d) => (
                <div
                  key={d.id}
                  className={`md:tw-w-full tw-w-[310px] tw-flex-shrink-0 tw-h-fit tw-flex tw-flex-col tw-justify-center tw-rounded-lg tw-p-3 tw-cursor-pointer ${selectedServices === d.id ? 'tw-bg-gradient-to-b tw-from-[#3BE4CE] tw-to-[#169E8C]' : 'tw-border tw-border-[#E6E6E6]'}`}
                  onClick={() => {
                    setSelectedServices(d.id);
                    setSelectedServiceImg(d.image);
                  }}
                >
                  <div className={`tw-flex tw-items-center tw-gap-3 ${selectedServices === d.id ? 'tw-text-white' : 'tw-text-[#798196]'}`}>
                    <h3 className="xl:tw-text-[34px] tw-text-[30px] tw-font-bold">{d.no}</h3>
                    <h4 className={`xl:tw-text-[30px] md:tw-text-[26px] tw-text-[20px] tw-font-bold ${d.key === "italic" ? 'tw-italic' : ''}`}>{d.name}</h4>
                  </div>
                  <div className={`tw-w-full tw-text-justify ${selectedServices === d.id ? 'tw-text-white' : 'tw-text-[#798196]'}`}>
                    <span
                      className="xl:tw-text-sm tw-text-base tw-leading-6 tw-text-justify" 
                      dangerouslySetInnerHTML={{
                        __html: d.note,
                      }}
                    >
                      {/* {d.note} */}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="xl:tw-w-[792px] tw-w-full xl:tw-h-fit tw-h-auto">
              <img src={selectedServiceImg} alt="service" className="tw-rounded-xl" />
            </div>
          </div>
        </div>

        {/* Class Digital */}
        <div className="xl:tw-w-[1280px] tw-w-full tw-px-10 xl:tw-px-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-w-full tw-flex tw-justify-start">
            <h2 className="tw-text-[#1D2B4F] xl:tw-text-5xl tw-text-4xl tw-leading-[60.34px] tw-font-bold">Pilih Kelas Kamu</h2>
          </div>
          <div className="tw-w-full tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-5">
            {allMateri.map((data, i) => (
              <div
                key={data.id}
                onClick={() => { handleConverUrl(data) }}
                className="md:tw-w-[350px] tw-w-full tw-h-[451px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-xl tw-cursor-pointer hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C] tw-text-black hover:tw-text-white tw-shadow-md tw-shadow-gray-400"
              >
                <div
                  className="text-decoration-none tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                >
                  <img src={data.image} alt="banner" className="tw-w-[320px] tw-h-auto tw-rounded-lg" />
                  <h6 className=" tw-text-lg tw-font-medium tw-text-center">{data.name}</h6>
                </div>
              </div>
            ))}
            {allMateri.length === 0 && (
              <EmptyContent
                title="No Data"
                sx={{
                  "& span.MuiBox-root": { height: 160 },
                }}
              />
            )}
          </div>
          <div className="tw-w-full tw-flex xl:tw-flex-row tw-flex-col tw-justify-between tw-items-center tw-gap-5">
            <div className="xl:tw-w-[600px] tw-w-full">
              <img
                src={Elibrary}
                alt="e-library"
                className="tw-rounded-lg tw-cursor-pointer"
                onClick={() => {
                  navigate('/ebooks')
                }}
              />
            </div>
            <div className="xl:tw-w-[600px] tw-w-full">
              <img
                src={Ejournal}
                alt="e-journal"
                className="tw-rounded-lg tw-cursor-pointer"
                onClick={() => {
                  navigate('/coming-soon')
                }}
              />
            </div>
          </div>
        </div>

        {/* Client Univ */}
        <div className="xl:tw-w-[1280px] tw-w-full md:tw-py-[80px] tw-py-[40px] tw-flex tw-flex-col tw-gap-10 xl:tw-px-0">
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-px-1 md:tw-px-0">
            <h2 className="tw-w-full xl:tw-w-[950px] tw-text-[#1D2B4F] md:tw-text-5xl tw-text-3xl xl:tw-leading-[63px] tw-text-center tw-font-bold tw-capitalize">Terintegrasi lebih dari 800 Program Studi di Perguruan Tinggi Se-Indonesia</h2>
          </div>
          <InfiniteScrollComponent />
        </div>

        {/* Testimony */}
        <div className="tw-w-full md:tw-py-[80px] tw-py-[40px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-10 tw-bg-[#E4E7EA]">
          <div className="tw-w-[410px] tw-flex tw-flex-col tw-justify-center tw-items-center">
            <h3 className="tw-text-[#4043FF] tw-font-bold tw-text-sm tw-leading-4 tw-tracking-[5px]">REVIEWS</h3>
            <h2 className="tw-text-[#1D2B4F] tw-text-4xl tw-tracking-[-0.5px] tw-text-center tw-font-bold">Why other people love to use our product</h2>
          </div>
          <div className="xl:tw-w-[1280px] tw-w-full">
            <InfiniteScrollTesti />
          </div>
        </div>

        {/* Mengapa Memilih kami */}
        <div className="xl:tw-w-[1280px] tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px] tw-px-10 xl:tw-px-0">
          <div className="tw-w-full tw-flex tw-justify-center">
            <h2 className="tw-text-[#1D2B4F] md:tw-text-5xl tw-text-4xl tw-leading-[43px] xl:tw-leading-[60.34px] tw-font-bold">Mengapa Mereka memilih kami?</h2>
          </div>
          <div className="tw-w-full tw-flex tw-flex-wrap tw-justify-between tw-gap-4">
            {DataCorporateValues.map((data, i) => (
              <div
                key={data.id}
                id="card-corp"
                className={`group md:tw-w-[350px] tw-w-full tw-h-[340px] tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center tw-rounded-xl tw-cursor-default hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C]`}
              >
                <img src={data.image} alt="banner" className="tw-w-[145px] tw-h-auto tw-rounded-lg" />
                <h6 className={`tw-text-lg tw-font-semibold tw-text-center tw-text-[#1D2B4F] group-hover:tw-text-white`}>{data.name}</h6>
                <span className="tw-text-base tw-text-medium tw-text-center tw-text-[#798196] group-hover:tw-text-white">{data.desc}</span>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ */}
        <div className="xl:tw-w-[1280px] tw-w-full tw-flex tw-flex-col xl:tw-flex-row tw-gap-5 tw-items-start tw-justify-between md:tw-py-[80px] tw-py-[40px] tw-relative tw-px-10 xl:tw-px-0">
          <div className="xl:tw-sticky tw-top-28 xl:tw-w-[500px] tw-w-full tw-flex tw-flex-col tw-justify-center tw-gap-3">
            <h2 className="tw-text-[#1D2B4F] xl:tw-text-5xl tw-text-4xl tw-leading-[43px] xl:tw-leading-[60.34px] tw-font-bold tw-italic">Frequently Asked Questions</h2>
            <span className="tw-text-base tw-text-[#636D79] tw-text-justify">Anda akan menemukan jawaban atas pertanyaan - pertanyaan yang paling banyak kami tanyakan tentang Plaform Pembelajaran Digital Alter Indonesia.</span>
            <a href="https://wa.me/6285161816009?text=Halo%20admin,%20saya%20mau%20bertanya..." target="_blank" rel="noreferrer nopenner" className="tw-w-fit tw-no-underline tw-text-sm tw-font-semibold tw-text-[#3A86FF] tw-border-b-2 tw-border-b-[#3A86FF] tw-italic">
              View All FAQ
            </a>
          </div>
          <div className="xl:tw-w-[700px] tw-w-full tw-flex tw-flex-wrap tw-justify-between tw-gap-4">
            <FaqComponents />
          </div>
        </div>

        {/* Banner Management */}
        <div className="xl:tw-w-[1280px] tw-w-full tw-flex tw-items-start tw-justify-between md:tw-py-[80px] tw-py-[40px] tw-relative tw-px-10 xl:tw-px-0">
          <div className="tw-absolute tw-top-[7rem] tw-left-[-70px] tw-hidden md:tw-block">
            <img src={DotsGreen} alt="dots" />
          </div>
          <img
            src={BannerManagement}
            alt="events"
            className="tw-rounded-lg tw-cursor-pointer tw-z-10"
            onClick={() => {
              navigate('/coming-soon')
            }}
          />
          <div className="tw-absolute tw-bottom-10 tw-right-[-40px] tw-hidden md:tw-block">
            <img src={DotsOrange} alt="dots" />
          </div>
        </div>

      </section>
        <FooterCustom />
    </Fragment>
  );
};

export default Home;
