// @mui
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
//
// import Image from "./Image";
import EmptyImg from "assets/images/illustration_empty_content.svg";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(8, 2),
}));

export default function EmptyContent({ title, description, img, ...other }) {
  return (
    <RootStyle {...other}>
      <img
        alt="empty content"
        src={EmptyImg}
        style={{ height: 240, marginBottom: 3 }}
      />

      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
