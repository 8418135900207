import Corp1 from 'assets/images/home/corporateValues/1-interaktif.webp';
import Corp2 from 'assets/images/home/corporateValues/2-tim-penyusun.webp';
import Corp3 from 'assets/images/home/corporateValues/3-paperless.webp';
import Corp4 from 'assets/images/home/corporateValues/4-user-friendly.webp';
import Corp5 from 'assets/images/home/corporateValues/5-free-updates.webp';
import Corp6 from 'assets/images/home/corporateValues/6-harga-affordable.webp';

export const DataCorporateValues = [
    {
        id: 1,
        image: Corp1,
        name: 'Interaktif dan Kompetibel',
        desc: 'Pembelajaran yang mudah dipahami dan interaktif. Bahkan bisa diakses sampai 2 gadget device, yakni handphone/tab dan laptop/komputer.'
    },
    {
        id: 2,
        image: Corp2,
        name: 'Tim Penyusun Terseleksi',
        desc: 'Tim penyusun berpengalaman dan kompeten dibidangnya yang didukung dengan visual menarik akan menciptakan cara belajar yang tidak membosankan.'
    },
    {
        id: 3,
        image: Corp3,
        name: 'Paper Less',
        desc: 'Kami mendungkun penuh untuk melestarikan alam dengan mengurangi dampak lingkungan dan meningkatkan produktifitas serta efisiensi.'
    },
    {
        id: 4,
        image: Corp4,
        name: 'User Friendly',
        desc: 'User Interface yang friendly akan memudahkan pengguna dalam mengakses materi yang disediakan.'
    },
    {
        id: 5,
        image: Corp5,
        name: 'Free Updates',
        desc: 'Pengguna akan mendapatkan fasilitas pembaruan materi secara otomatis dan berkala tanpa ada penambahan biaya.'
    },
    {
        id: 1,
        image: Corp6,
        name: 'Harga Affordable',
        desc: 'Kami memberikan penawaran harga spesial terhadap instansi/perguruan tinggi yang sudah berkerja sama.'
    },
]