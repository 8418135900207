import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ButtonCustom, Diskon, TitleInfo, Banner } from "./StudiesStyle";
import { toast } from "react-toastify";

import axios from "helpers/GatewayService";
import { getDetailsEbook } from "redux/slices/Ebooks";
import { getLinkCheckout } from "redux/slices/Materi";
import { getTestimonials } from "redux/slices/Testimonials";

import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import ModalLogin from "components/organisms/ModalLogin";
import CarouselImage from "assets/images/timPenyusun/carousel-team.png";
import { API_URL } from "helpers/ENVState";

const DetailsStudies = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { ebook, isLoadingEbooks } = useSelector((state) => state.ebooks);
  const { banners } = useSelector((state) => state.banners);

  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [state, setState] = useState({
    title: null,
    about: null,
    picture: null,
    includes: null,
    price: null,
    price_discount: null,
    grand_total: null,
    discount: null,
    name_discount: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(getTestimonials());
      dispatch(getDetailsEbook(id));
    }
  }, [id, dispatch]);


  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "View Details Perpustakaan Digital");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (!isLoadingEbooks && ebook) {
      setState((prev) => ({
        title: ebook.name,
        picture: ebook.picture,
        about: ebook.tentang,
        includes: ebook.includes,
        price: ebook.price,
        grand_total: ebook.grand_total,
        name_discount:
          ebook.name_discount ||
            ebook.name_discount !== "null" ||
            ebook.name_discount !== "undefined"
            ? ebook.name_promo
            : null,
        discount:
          ebook.discount ||
            ebook.name_discount !== "null" ||
            ebook.name_discount !== "undefined"
            ? ebook.discount
            : null,
        price_discount:
          ebook.price_discount ||
            ebook.name_discount !== "null" ||
            ebook.name_discount !== "undefined"
            ? ebook.price_discount
            : null,
      }));
    }
  }, [isLoadingEbooks, ebook]);

  function fCurrency(number) {
    if (number) {
      const reverse = number.toString().split("").reverse().join("");
      let ribuan = reverse.match(/\d{1,3}/g);
      const result = `Rp. ${ribuan.join(".").split("").reverse().join("")}`;
      return result;
    }
  }

  const toggleModalLogin = () => setModal(!modal);

  const handleCheckout = () => {
    const user = Cookies.get("user");
    if (user) {
      setModalAlert(!modalAlert);
    }
    if (!user) {
      toggleModalLogin();
    }
  };

  const handleSubmit = async (payload) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.post(API_URL + "/api/login", payload);

    if (!response.data.error) {
      const { accessToken, user } = response.data.result;

      const session = {
        token: accessToken,
        user,
      };
      Cookies.set("user", JSON.stringify(session), {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 30,
      });
    }
  };

  const handleCheckoutSubmit = async () => {
    setLoading(true);
    const payload = {
      amount: parseInt(ebook.grand_total),
      name_class: ebook.name,
      id_class: ebook.id,
      type: 'ebook',
    };
    const resp = await getLinkCheckout(payload);

    if (resp.status === "success") {
      setLoading(false);
      window.open(resp.result, "_blank");
      setModalAlert(!modalAlert);
    }
    if (resp.response.data.status === "failed") {
      setLoading(false);
      return toast.error(resp.response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <SEO
        title="Materi Sahabat Alter Indonesia"
        description="Alter merupakan aplikasi pembelajaran yang berisi materi perkuliahan kesehatan dalam bentuk teks dan video yang interaktif."
        keywords="Kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi, Ilmu Gizi, Sahabat Alter, PT Sahabat Alter Indonesia, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid />

      {/* Teams Alter */}
      <Container className="my-5">
        <Row>
          <Col
            lg={5}
            className="py-5"
            style={{
              height: "auto",
              position: "relative",
              marginY: 2,
              borderRadius: "18px",
            }}
          >
            <img
              src={`${API_URL}/${state.picture}`}
              alt="cover"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Col>
          <Col lg={6} className="my-5">
            <Row className="w-100 flex-wrap">
              <Col
                lg={12}
                className={`${state.name_discount ? "d-flex" : "d-none"
                  } justify-content-start`}
              >
                <Diskon>
                  <span className="text-uppercase">{`${state.name_discount}`}</span>
                </Diskon>
              </Col>
              <Col lg={12} className="d-flex flex-column justify-content-start">
                <TitleInfo>{state.title}</TitleInfo>
                <div
                  className="w-100"
                  style={{
                    textAlign: 'justify'
                  }}
                  dangerouslySetInnerHTML={{
                    __html: state.about,
                  }}
                />
              </Col>
              <Col lg={12} className="w-100">
                <Row className="align-items-center">
                  <Col lg={6} className="d-flex flex-column">
                    {state.price_discount && (
                      <span className="fw-bold price-discount">
                        {fCurrency(state.price)}
                      </span>
                    )}
                    <span className="fw-bold price-normal">
                      {fCurrency(state.grand_total)}
                    </span>
                  </Col>
                  <Col lg={6}>
                    <ButtonCustom onClick={handleCheckout}>
                      Beli Sekarang
                    </ButtonCustom>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <FooterCustom section={true} />

      <ModalLogin
        modalLogin={modal}
        setModalLogin={setModal}
        toggleModalLogin={toggleModalLogin}
        handleSubmit={handleSubmit}
      />

      <Dialog
        open={modalAlert}
        onClose={() => setModalAlert(!modalAlert)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin ingin membeli buku ini?
        </DialogTitle>
        <DialogContentText sx={{ textAlign: "center", my: 2 }}>
          Anda akan diarahkan ke halaman xendit.
        </DialogContentText>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button color="inherit" onClick={() => setModalAlert(!modalAlert)}>
            Batal
          </Button>
          <LoadingButton
            color="success"
            onClick={handleCheckoutSubmit}
            autoFocus
            loading={loading}
          >
            Yakin
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailsStudies;
