import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "helpers/GatewayService";
//
import { dispatch } from "redux/store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  banners: [],
};

const slice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET banner
    getBannersSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllBanners() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/banner");
      dispatch(slice.actions.getBannersSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
