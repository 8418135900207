import styled from "styled-components";
import { Col, Container } from "reactstrap";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: 100% 90%;
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top -10px center;
  }
`;

export const Title = styled.span`
  width: 300px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000;
`;

export const Note = styled.h3`
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SectionCustom = styled.div`
  width: 100%;
  border-radius: 30px;
  background: linear-gradient(78.35deg, #05a390 16.62%, #1170a6 102.28%);
`;

export const TitleInfo = styled.h2`
  font-weight: 700;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 430px) {
    font-size: 38px;
  }
`;

export const NoteInfo = styled.span`
  width: 60%;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  @media screen and (max-width: 430px) {
    font-size: 18px;
  }

`;

export const NoteInfoMisi = styled.div`
  width: 90%;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  ul li {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 430px) {
    font-size: 18px;

    ul li {
      margin-bottom: 3px;
    }
  }

  @media screen and (max-width: 380px) {
    font-size: 15px;
    line-height: 22px;

    ul li {
      margin-bottom: 2px;
    }
  }
`;

export const TitleContainer = styled.h3`
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 840px) {
    font-size: 38px;
    line-height: 52px;
  }

  @media screen and (max-width: 430px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media screen and (max-width: 380px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const NoteBanner = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  color: #000000;

  ul li {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 840px) {
    font-size: 18px;
    line-height: 130%;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;

    ul {
      margin-left: 10px;
    }
  }
`;

export const TextYear = styled.h2`
  font-weight: 700;
  font-size: 90px;
  line-height: 110px;
  text-align: center;
  color: #000000;

  @media screen and (max-width: 430px) {
    line-height: 80px;
  }
`;

export const TitleYear = styled.h6`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

export const NoteTentang = styled.span`
  font-size: 1.25rem;

  @media screen and (max-width: 840px) {
    font-size: 1.1rem;
    padding: 20px;
  }

  @media screen and (max-width: 430px) {
    font-size: 18px;
  }
`;

export const BoxBlue = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 680px;
  height: 400px;
  z-index: 999;
  right: 100px;
  top: 70px;
  border-width: 8px;
  border-style: solid;
  border-image: linear-gradient(80.87deg, #29a6e2 16.49%, #5753f5 103.22%) 1;
  border-radius: 30px;

  @media (min-width: 1920px) {
    display: none;
  }

  @media screen and (max-width: 840px) {
    top: 10px;
    right: 50px;
    width: 400px;
    height: 300px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const BoxBlueChoose = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 600px;
  height: 400px;
  z-index: 999;
  right: 0;
  top: 50px;
  border-width: 8px;
  border-style: solid;
  border-image: linear-gradient(80.87deg, #29a6e2 16.49%, #5753f5 103.22%) 1;
  border-radius: 30px;

  @media screen and (max-width: 840px) {
    width: 380px;
    height: 250px;
    top: 10px;
    right: 10px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ImageAboutLeft = styled.img`
  width: 630px;
  height: 400px;
  border-radius: 30px;

  @media screen and (max-width: 840px) {
    width: 400px;
    height: 300px;
  }

  @media screen and (max-width: 768px) {
    width: 350px;
    height: 250px;
  }

  @media screen and (max-width: 430px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 380px) {
    width: 300px;
    height: 200px;
  }

`;

export const ImageAboutRight = styled.img`
  width: 650px;
  height: 400px;
  border-radius: 30px;

  @media screen and (max-width: 840px) {
    width: 400px;
    height: 300px;
  }

  @media screen and (max-width: 768px) {
    width: 350px;
    height: 250px;
  }

  @media screen and (max-width: 430px) {
    display: none;
  }
`;

export const ImageChoose = styled.img`
  height: 430px;
  border-radius: 30px;

  @media screen and (max-width: 840px) {
    width: 400px;
    height: 230px;
  }
  
  @media screen and (max-width: 430px) {
    width: 340px;
    height: 200px;
  }
`;

export const AboutContentLeft = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;

  .row {
    .content-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 100%;
    }
  }

  @media screen and (max-width: 430px) {
    margin-left: 0;
  }

  @media screen and (max-width: 380px) {
    .row {
      .content-left {
        width: inherit;
        align-items: center;
      }
    }
  }
`;

export const AboutContentRight = styled(Col)`
  margin-left: -50px;

  @media screen and (max-width: 430px) {
    margin-left: 0;
  }
`;
