import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import CarouselImage from "assets/images/under-con.webp";

const EjournalPage = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <section className="tw-w-screen tw-h-screen tw-bg-[#22C9A5]">
        <img
            src={CarouselImage}
            alt=""
            className="tw-cursor-pointer tw-h-auto tw-w-screen tw-hidden xl:tw-block"
            onClick={() => {
                navigate('/')
            }}
        />
        <img
            src={CarouselImage}
            alt=""
            className="tw-cursor-pointer tw-h-screen tw-w-screen tw-block xl:tw-hidden tw-object-contain"
            onClick={() => {
                navigate('/')
            }}
        />
      </section>
    </Fragment>
  );
};

export default EjournalPage;
