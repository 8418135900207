// @mui
import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel
} from '@mui/material';

// ----------------------------------------------------------------------

export default function TableHeadCustom({
  headLabel,
  sx,
}) {
  return (
    <TableHead sx={sx}>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth, bgcolor: '#F4F6F8', color: '#637381', fontWeight: '600'}}
          >
            <TableSortLabel
                hideSortIcon
                active={false}
                direction={'asc'}
                sx={{ textTransform: 'capitalize', fontWeight: '600s' }}
            >
                {headCell.label}
              </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
