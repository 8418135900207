import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "helpers/GatewayService";
//
import { dispatch } from "redux/store";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingArticle: false,
  error: null,
  articles: null,
  articles_with_page: null,
  article: null,
};

const slice = createSlice({
  name: "article",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingArticle = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingArticle = false;
      state.error = action.payload;
    },

    // GET all Article
    getArticlesSuccess(state, action) {
      state.isLoadingArticle = false;
      state.articles = action.payload;
    },

    getArticlesSuccessWithPage(state, action) {
      state.isLoadingArticle = false;
      state.articles_with_page = action.payload;
    },

    // GET article
    getArticleSuccess(state, action) {
      state.isLoadingArticle = false;
      state.article = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getArticles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/article");
      dispatch(slice.actions.getArticlesSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getArticlesWithPage(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const link = `/api/all-article?page=${params.page}&limit=${params.limit}`;
      const response = await axios.get(link);
      dispatch(slice.actions.getArticlesSuccessWithPage(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getArticle(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/detail-article/${key}`);
      dispatch(slice.actions.getArticleSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
