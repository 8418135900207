export const DataNavigations = [
    {
        label: 'Tentang Kami',
        path: '/about',
        value: "about"
      },
      {
        label: 'Tim Penyusun',
        path: '/teams',
        value: "teams"
      },
      {
        label: 'Kelas Digital',
        path: '/studies',
        value: "studies"
      },
      {
        label: 'Perpustakaan Digital',
        path: '/ebooks',
        value: "ebooks"
      },
      {
        label: 'Artikel',
        path: '/articles',
        value: "articles"
      },
      {
        label: 'Kontak',
        path: '/contact',
        value: "contact"
      },
]