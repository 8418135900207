import "./index.css";
import "./css/App.css";
import 'swiper/css';
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "redux/store";
import Router from "routes";
const helmetContext = {};

function App() {
  return (
    <ReduxProvider store={store}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </HelmetProvider>
    </ReduxProvider>
  );
}

export default App;
