// @mui
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function EbookCard({ post }) {
  return (
    <Card sx={{ height: 520, border: 'none', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)', borderRadius: '18px' }} className="tw-flex tw-flex-col tw-justify-between tw-items-center tw-rounded-xl tw-cursor-pointer hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C] tw-text-black hover:tw-text-white tw-shadow-md tw-shadow-gray-400">
      <CardMedia component="img" alt={post.name} src={post.image} sx={{ height: 400, objectFit: 'fill' }} />

      <PostContent title={post.name} author={post.author} />
    </Card>
  );
}

// ----------------------------------------------------------------------

export function PostContent({ title, author }) {
  return (
    <CardContent
      sx={{
        pt: 1,
        width: '100%',
        m: 0,
        cursor: 'pointer',
        border: 'none',
      }}
    >
      <Typography variant="body1" line={1} persistent sx={{ textTransform: "capitalize", fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant={'body2'} line={1} persistent sx={{ textTransform: "capitalize" }}>
        {author}
      </Typography>
    </CardContent>
  );
}
