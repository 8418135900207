import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { API_URL } from "helpers/ENVState";
import { getAllMateri } from "redux/slices/Materi";

import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import CarouselImage from "assets/images/banner/header-banner-about.png";
import EmptyContent from "components/organisms/EmptyContent";

import {
  Banner,
  Title,
  Note,
} from "./StudiesStyle";

const Studies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);

  const [allMateri, setAllMateri] = useState([]);

  const { banners } = useSelector((state) => state.banners);
  const { materis, isLoadingMateri } = useSelector((state) => state.materis);

  useEffect(() => {
    dispatch(getAllMateri());
  }, [dispatch]);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Materi");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (!isLoadingMateri && materis.length !== 0) {
      let temp = [];
      materis.forEach((element) => {
        temp.push({
          id: element.id,
          name: element.name,
          type: element.type,
          image: `${API_URL}/${element.picture}`,
        });
      });
      setAllMateri([...temp]);
    }
  }, [isLoadingMateri, materis]);

  const handleConverUrl = (data) => {
    let url = "#";
    if (data) {
      let modifiedString = data.name
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .toLowerCase();
      modifiedString = modifiedString.replace(/\s+/g, "-");
      if (data.type === "materi") {
        url = `/studies/details/${modifiedString}/${data.id}`;
      }
      if (data.type === "ukom") {
        url = `/ukom/details/${modifiedString}/${data.id}`;
      }
    }
    navigate(url);
  };

  return (
    <>
      <SEO
        title="Materi Sahabat Alter Indonesia"
        description="Alter merupakan aplikasi pembelajaran yang berisi materi perkuliahan kesehatan dalam bentuk teks dan video yang interaktif."
        keywords="Kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi, Ilmu Gizi, Sahabat Alter, PT Sahabat Alter Indonesia, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>

      {/* Studies */}
      <Container className="my-5">
        <Row>
          <Col
            lg={12}
            className="d-flex flex-column justify-content-center align-items-center "
          >
            <Title>Kelas Digital</Title>
            <Note>Pilih kelas digital yang kamu minati</Note>
          </Col>
          <Col lg={12} className="mt-5">
            <div className="tw-w-full tw-flex tw-flex-wrap tw-justify-between tw-gap-4">
              {allMateri.map((data, i) => (
                <div
                  key={data.id}
                  onClick={() => { handleConverUrl(data) }}
                  className="md:tw-w-[350px] tw-w-full tw-h-[451px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-xl tw-cursor-pointer hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C] tw-text-black hover:tw-text-white tw-shadow-md tw-shadow-gray-400"
                >
                  <div
                    className="text-decoration-none tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2"
                  >
                    <img src={data.image} alt="banner" className="tw-w-[320px] tw-h-auto tw-rounded-lg" />
                    <h6 className=" tw-text-lg tw-font-medium tw-text-center">{data.name}</h6>
                  </div>
                </div>
              ))}
              {allMateri.length === 0 && (
                <EmptyContent
                  title="No Data"
                  sx={{
                    "& span.MuiBox-root": { height: 160 },
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <FooterCustom section={true} />
    </>
  );
};

export default Studies;
