import styled from "styled-components";
import { Button, Col, Container } from "reactstrap";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: cover;
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top -10px center;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 768px) {
    font-size: 38px;
    line-height: 52px;
  }

  @media screen and (max-width: 430px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media screen and (max-width: 380px) {
    font-size: 24px;
    line-height: 32px;
  }

`;

export const Note = styled.span`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    
    @media screen and (max-width: 840px) {
      width: 60%;
      font-size: 22px;
    }
  
    @media screen and (max-width: 430px) {
      width: 90%;
      font-size: 20px;
      line-height: 24px;
    }
  
    @media screen and (max-width: 380px) {
      width: 80%;
      font-size: 18px;
    }
`;

export const CardTeams = styled.div`
  position: relative;
  width: 300px;
  height: 400px;
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  cursor: pointer;

  @media screen and (max-width: 835px) {
    width: 180px;
    height: 280px;
  }

  @media screen and (max-width: 430px) {
    width: 300px;
    height: 340px;
  }
`;

export const SectionTeams = styled(Col)`
  width: 300px;
  max-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h6 {
    width: 100%;
    text-align: center;
  }

  span {
    color: #637381;
    font-size: 1rem;
    font-weight: bold;
  }

  @media screen and (max-width: 835px) {
    width: 200px;
  }

  @media screen and (max-width: 430px) {
    width: 300px;
  }
`;

export const ButtonCustom = styled(Button)`
  width: 220px;
  height: 50px;
  background: #eeeb4b !important;
  border-radius: 10px;
  border: none;
  margin-top: 50px;
  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  color: #0f7ca1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;