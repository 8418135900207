import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "helpers/GatewayService";
//
import { dispatch } from "redux/store";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingEbooks: false,
  error: null,
  ebooks: [],
  ebook: null,
};

const slice = createSlice({
  name: "ebooks",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Materi all
    getEbooksSuccess(state, action) {
      state.isLoading = false;
      state.ebooks = action.payload;
    },

    // GET Materi one
    getEbookSuccess(state, action) {
      state.isLoading = false;
      state.ebook = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllEbooks() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/ebooks");
      dispatch(slice.actions.getEbooksSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDetailsEbook(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/detail-ebook-preview/${key}`);
      dispatch(slice.actions.getEbookSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
