import { useState } from "react";
import plus from "assets/icons/plus.svg";
import minus from "assets/icons/minus.svg";

export default function Accordion({
  title,
  content,
}) {
  const [isActive, setIsActive] = useState(1);
  const minuSign = <img src={minus} alt="minus sign" className="tw-w-[24px] tw-h-[24px]" />;
  const pluSign = <img src={plus} alt="plus sign" className="tw-w-[24px] tw-h-[24px]" />;

  const handleActive = (key) => {
    if (key === isActive) {
      setIsActive()
    }
    if (key !== isActive) {
      setIsActive(key)
    }
  };

  return (
    <>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(1)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Apa itu Alter Indonesia?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 1 ? minuSign : pluSign}</div>
        </div>
        {isActive === 1 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Alter Indonesia merupakan platform pembelajaran yang berisi kelas digital, kegiatan secara daring maupun luring dan soal-soal simulasi uji kompetensi bagi mahasiswa/i Kesehatan yang dilengkapi dengan pembahasan dalam bentuk tertulis maupun video.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(2)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Apakah saya perlu berlangganan untuk mengakses materi?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 2 ? minuSign : pluSign}</div>
        </div>
        {isActive === 2 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Tidak perlu. Kamu cukup melakukan pembayaran diawal dan tidak ada biaya langganan baik mingguan, bulanan maupun tahunan.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(3)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Bagaimana saya bisa mengetahui Universitas saya menjalin kerja sama dengan Alter Indonesia atau tidak?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 3 ? minuSign : pluSign}</div>
        </div>
        {isActive === 3 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Kamu bisa hubungi <a href="https://wa.me/6285161816009?text=Halo%20admin,%20saya%20mau%20bertanya..." target="_blank" rel="noreferrer nopenner" className="tw-w-fit tw-no-underline tw-text-sm tw-font-semibold tw-text-[#3A86FF] tw-border-b-2 tw-border-b-[#3A86FF] tw-italic">customer care</a> kami untuk mengkonfirmasi dan validasi status kerja sama kampus kamu dengan Alter Indonesia.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(4)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Benefit apa saja yang akan saya dapat jika saya memesan paket pembelajaran di Alter Indonesia?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 4 ? minuSign : pluSign}</div>
        </div>
        {isActive === 4 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              1. Tidak Berlangganan. Pengguna hanya perlu melakukan pembayaran diawal sekali saja. Tanpa ada biaya tambahan langganan baik bulanan maupun tahunan untuk mengakses kelas yang sudah dimiliki.
              <br />
              <br />
              2. Garansi Akses. Pengguna akan mendapatkan garansi akses untuk mempelajari materi kelas digital yang sudah tersedia.
              <br />
              <br />
              3. Bebas Biaya Pembaruan. Pengguna akan mendapatkan fasilitas pembaruan materi secara otomatis dan berkala tanpa ada penambahan biaya.
              <br />
              <br />
              4. Request Journal. Pengguna mendapatkan fasilitas request jurnal yang akan dicarikan oleh penyedia tanpa ada biaya tambahan.
              <br />
              <br />
              5. Akses Perpustakaan Digital. Pengguna akan diberikan akses ebook yang tersedia sebagai pengayaan materi.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(5)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Siapa tim dosen dan penyusun Alter Indonesia?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 5 ? minuSign : pluSign}</div>
        </div>
        {isActive === 5 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Tim dosen dan penyusun Alter Indonesia merupakan tim pengajar yang terseleksi dengan baik, berpengalaman serta kompeten dalam berbagai bidang kesehatan.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(6)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Apakah aplikasi Alter bisa di pakai selamanya?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 6 ? minuSign : pluSign}</div>
        </div>
        {isActive === 6 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Tentu bisa. Pembelajaran digital Alter Indonesia tidak memiliki masa penggunaan. Sehingga pengguna mendapatkan akses secara lifetime.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(7)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Bagaimana cara memperbarui (update) materi Alter Indonesia?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 7 ? minuSign : pluSign}</div>
        </div>
        {isActive === 7 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Tentu secara otomatis. Sistem pembelajaran yang sudah terintegrasi akan memudahkan pengguna dalam memperoleh materi terbaru.
            </p>
          </div>
        )}
      </div>
      <div className="tw-w-full tw-shadow tw-p-4 tw-rounded-lg">
        <div
          className="tw-flex tw-select-none tw-cursor-pointer tw-justify-between tw-items-center"
          onClick={() => handleActive(8)}
        >
          <div className="tw-w-[90%]">
            <h3 className="tw-text-[#1D2B4F] tw-text-base tw-font-semibold tw-transition-all tw-duration-[250ms] tw-ease-in-out">
              Bagaimana caranya untuk mendapatkan materi di Alter Indonesia?
            </h3>
          </div>
          <div className="tw-w-[10%] tw-h-[24px]">{isActive === 8 ? minuSign : pluSign}</div>
        </div>
        {isActive === 8 && (
          <div className="tw-w-full">
            <p className="tw-text-[#1D2B4F] tw-text-sm tw-opacity-80 tw-leading-normal tw-text-justify">
              Kamu bisa melakukan pendaftaran di kampus agar mendapatkan penawaran special dan edukasi yang lebih detail dari tim internal kami.
            </p>
          </div>
        )}
      </div>
    </>
  );
}