import Marquee from "react-fast-marquee";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { API_URL } from "helpers/ENVState";
import { ReadMore } from "components/atoms/Readmore";

import Testi1 from "assets/images/testimoni/testi1.jpeg";
import Testi2 from "assets/images/testimoni/testi3.jpeg";
import Testi3 from "assets/images/testimoni/testi2.jpeg";
import Dinia from "assets/images/testimoni/dinia-ratih.jpg";
import Eka from "assets/images/testimoni/eka-putri-suhantora.jpg";
import Herlisa from "assets/images/testimoni/her-lisa.jpg";
import Nur from "assets/images/testimoni/nur-afiza.jpg";


const Cerita = [
    {
        id: 1,
        name: "Ira",
        image: Testi1,
        univ: "Akbid Respati Tasikmalaya",
        prodi: "Kebidanan",
        pesan:
            "Alhamdulillah setelah 4x belum lulus Uji Kompetensi bisa lulus uji kompetensi kebidanan berkat ikut bimbingan tim pengajar Alter Indonesia",
    },
    {
        id: 2,
        name: "Ramadhan",
        image: Testi2,
        univ: "Poltekkes Kemenkes Tasikmalaya",
        prodi: "Keperawatan",
        pesan:
            "Alhamdulilah materi2nya passs sma kurikulum, bahkan materi2 yg gk ada d kampus ada d alter jadi enak bgt buat persiapan persentasi tugas, apalgi materi dan video sop sangat membantu di jaman kuliah onlen ini kak, dan yg paling sya suka sih daftar obat ituuu komplittt jdi gak perlu bolai balik buka google, apalgi bayar sekali bisa buat di laptop dan hp hehehe, oiya smoga nanti latihan ujikom d alter juga bisa membantu saya insha Allah tahun depan amminnn",
    },
    {
        id: 3,
        name: "Mumtaz Wahyu",
        image: Testi3,
        univ: "Universitas Jenderal Soedirman",
        prodi: "Kedokteran",
        pesan:
            "Alter materinya sangat lengkap dan terbaru, dari semua materi yang ada di aplikasi semua bisa digunakan di sistem pembelajar anak kedokteran sehingga sangat memudahkan materi-materi untuk pembelajaran kedokteran, mencarinya juga gampang karena di sesuaikan dengan silabus pembelajaran FK",
    },
    {
        id: 4,
        name: "Dinia Rati Astuti",
        image: Dinia,
        univ: "Universitas Muhammadiyah Riau",
        prodi: "Keperawatan",
        pesan: "Bagus, Menarik, Memudahkan dalam mencari materi pembelajaran.",
    },
    {
        id: 5,
        name: "Nur Afiza",
        image: Nur,
        univ: "Universitas Muhammadiyah Riau",
        prodi: "Keperawatan",
        pesan:
            "Ramah, penjelasannya jelas, aplikasinya akan banyak membantu saya kedepannya",
    },
    {
        id: 6,
        name: "Herlisa Telaumbanua",
        image: Herlisa,
        univ: "Universitas Muhammadiyah Riau",
        prodi: "Keperawatan",
        pesan: "Sangat bagus, Terima kasih Alter.",
    },
    {
        id: 7,
        name: "Eka Putri Suhantora",
        image: Eka,
        univ: "Universitas Muhammadiyah Riau",
        prodi: "Keperawatan",
        pesan:
            "Aplikasi bagus banget bagi saya yang suka males nyari materi keperawatan di berbagai internet yang menurut saya ribet, jadi ini membantu dan sangat bermanfaat sekali bagi saya. Semoga makin bagus lagi kedepannya.",
    },
];

const InfiniteScrollComponent = () => {
    const [testimoniValue, setTestimoniValue] = useState([]);
    const { testimonials, isLoading } = useSelector(
        (state) => state.testimonials
    );

    useEffect(() => {
        if (!isLoading && testimonials.length !== 0) {
            let temp = [];
            testimonials.forEach((element) => {
                temp.push({
                    id: element.id,
                    name: element.user.first_name + " " + element.user.last_name,
                    image: `${API_URL}/${element.user.image}`,
                    univ: element.user.university,
                    prodi: element.user.faculty,
                    pesan: element.testimoni,
                });
            });
            setTestimoniValue(temp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testimonials, isLoading]);

    return (
        <div className="tw-flex tw-flex-col tw-gap-10">
            <Marquee play={true} pauseOnHover direction="right" speed={30} delay={0} loop={0} autoFill gradientColor="rgb(228 231 234)">
                {Cerita.slice(0, 3).map((d) => (
                    <figure key={d.id} id="testimonial-card" className="tw-relative tw-w-[340px] tw-mx-10 tw-flex tw-min-h-[400px] tw-flex-col tw-justify-between tw-items-center tw-gap-5 tw-overflow-hidden tw-rounded-xl tw-border tw-bg-white tw-p-5 tw-shadow-sm">
                        <img loading="lazy" className="tw-mr-3 tw-aspect-square tw-h-20 tw-w-20 tw-rounded-full tw-border-2 tw-border-main-500 tw-bg-main-200 tw-object-cover tw-p-0.5" src={d.image} alt="Aulia Ramadhani Ansar" />
                        <div className="tw-w-full">
                            <ReadMore id={`read-more-text-${d.id}`} text={d.pesan} amountOfWords={30} />
                        </div>
                        <figcaption className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                            <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
                                <div className="tw-w-[60px] tw-h-[2px] tw-border tw-border-black"></div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-items-center">
                                <span className="tw-font-semibold">{d.name}</span>
                                <span className="tw-text-secondary-400 tw-text-xs tw-font-light xs:tw-text-sm">{d.univ}</span>
                            </div>
                        </figcaption>
                    </figure>
                ))}
            </Marquee>
            {testimoniValue.length > 0 ? (
                <Marquee play={true} pauseOnHover direction="left" speed={10} delay={0} loop={0} autoFill={true} gradientColor="rgb(228 231 234)">
                    {testimoniValue.map((d) => (
                        <figure key={d.id} id="testimonial-card" className="tw-relative tw-w-[340px] tw-mx-10 tw-flex tw-min-h-[400px] tw-flex-col tw-justify-between tw-items-center tw-gap-5 tw-overflow-hidden tw-rounded-xl tw-border tw-bg-white tw-p-5 tw-shadow-sm">
                            <img loading="lazy" className="tw-mr-3 tw-aspect-square tw-h-20 tw-w-20 tw-rounded-full tw-border-2 tw-border-main-500 tw-bg-main-200 tw-object-cover tw-p-0.5" src={d.image} alt="Aulia Ramadhani Ansar" />
                            <div className="tw-w-full">
                                <ReadMore id={`read-more-text-${d.id}`} text={d.pesan} amountOfWords={30} />
                            </div>
                            <figcaption className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                                <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
                                    <div className="tw-w-[60px] tw-h-[2px] tw-border tw-border-black"></div>
                                </div>
                                <div className="tw-flex tw-flex-col tw-items-center">
                                    <span className="tw-font-semibold">{d.name}</span>
                                    <span className="tw-text-secondary-400 tw-text-xs tw-font-light xs:tw-text-sm">{d.univ}</span>
                                </div>
                            </figcaption>
                        </figure>
                    ))}
                </Marquee>
            ) : (
                <Marquee play={true} pauseOnHover direction="left" speed={30} delay={0} loop={0} autoFill gradientColor="rgb(228 231 234)">
                {Cerita.slice(3, Cerita.length).map((d) => (
                    <figure key={d.id} id="testimonial-card" className="tw-relative tw-w-[340px] tw-mx-10 tw-flex tw-min-h-[400px] tw-flex-col tw-justify-between tw-items-center tw-gap-5 tw-overflow-hidden tw-rounded-xl tw-border tw-bg-white tw-p-5 tw-shadow-sm">
                        <img loading="lazy" className="tw-mr-3 tw-aspect-square tw-h-20 tw-w-20 tw-rounded-full tw-border-2 tw-border-main-500 tw-bg-main-200 tw-object-cover tw-p-0.5" src={d.image} alt="Aulia Ramadhani Ansar" />
                        <div className="tw-w-full">
                            <ReadMore id={`read-more-text-${d.id}`} text={d.pesan} amountOfWords={30} />
                        </div>
                        <figcaption className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                            <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
                                <div className="tw-w-[60px] tw-h-[2px] tw-border tw-border-black"></div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-items-center">
                                <span className="tw-font-semibold">{d.name}</span>
                                <span className="tw-text-secondary-400 tw-text-xs tw-font-light xs:tw-text-sm">{d.univ}</span>
                            </div>
                        </figcaption>
                    </figure>
                ))}
            </Marquee>
            )}
        </div>
    )
};

export default InfiniteScrollComponent;