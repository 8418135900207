import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Pagination from "../Paginate";
import DataFooterShortcut from "./DataFooterShortcut";
import HomeFooter from "assets/images/home/home-footer.png";
import Logo from "assets/images/alter.png";
import Ig from "assets/icons/instagram.png";
import Fb from "assets/icons/facebook.png";
import Yt from "assets/icons/youtube.png";
import Tk from "assets/icons/tk.png";
import Email from "assets/icons/mail.png";
import Phone from "assets/icons/phone.png";

import "./Footer.css";
import moment from "moment";

const FooterStyled = styled.footer`
  width: 100%;
  height: 100vh;
  background: url(${HomeFooter}) #eaf7f3;
  background-repeat: no-repeat;
  background-position: top 120px center;
  background-size: cover;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;

    .container-footer-component {
      margin-top: auto;
    }
  }

  @media screen and (max-width: 840px) {
    height: 60vh;
    background-position: bottom center;
    background-size: contain;
  }

  @media screen and (max-width: 430px) {
    .container {
      background: linear-gradient(
        180deg,
        #05a390 0%,
        #1171a6 99.99%,
        rgba(17, 112, 166, 0) 100%
      );
      .container-footer-component {
        padding: 20px;
        color: #fff;
      }

      .bottom-logo {
        color: #fff;
      }
    }

    height: 100vh;
    background-position: bottom center;
    background-size: contain;
  }

  @media screen and (max-width: 330px) {
    height: 140vh;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 54px;
  line-height: 96px;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 840px) {
    font-size: 30px;
    line-height: 52px;
  }

  @media screen and (max-width: 430px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

const ButtonFooter = styled.button`
  width: 228px;
  height: 60px;
  background: #eeeb4b;
  border-radius: 10px;
  border: none;
  margin: 0 30px;

  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  color: #0f7ca1;

  @media screen and (max-width: 840px) {
    width: 200px;
    height: 60px;
    line-height: 24px;
  }

  @media screen and (max-width: 430px) {
    width: 150px;
    height: 40px;
    font-size: 18px;
  }
`;

const TitleInfo = styled.h2`
  font-weight: 700;
  font-size: 64px;
  line-height: 74px;
  text-align: center;
  color: #0f7ca1;
`;

const NoteInfo = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #0f7ca1;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const dataSocialMedia = [
  {
    id: 1,
    name: "@alter.indonesia",
    icon: Ig,
    path: "https://instagram.com/alter.indonesia?igshid=Zjc2ZTc4Nzk=",
  },
  {
    id: 2,
    name: "Sahabat Alter Indonesia",
    icon: Fb,
    path: "https://www.facebook.com/profile.php?id=100070750906913&mibextid=LQQJ4d",
  },
  {
    id: 3,
    name: "Sahabat Alter Indonesia",
    icon: Yt,
    path: "https://www.youtube.com/@SahabatAlterIndonesia",
  },
  {
    id: 4,
    name: "Sahabat Alter Indonesia",
    icon: Tk,
    path: "https://www.tiktok.com/@alter.indonesia?_t=8XQWQfNMbon&_r=1 ",
  },
];

const FooterCustom = (props) => {
  const { section, pagination } = props;
  const [counterOn, setCounterOn] = useState(false);
  const year = moment().year();

  const handleCounter = () => setCounterOn(!counterOn);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FooterStyled>
      <div className="d-flex justify-content-center align-items-center my-5 h-lg-25">
        {section && !pagination ? (
          <ScrollTrigger
            onEnter={handleCounter}
            onExit={handleCounter}
            className="w-100 d-flex justify-content-center align-items-center"
          >
            <div className="row d-flex justify-content-between align-items-center w-100">
              <Col lg={4} md={4} className="text-center my-lg-0 my-3">
                <TitleInfo>
                  {counterOn && (
                    <CountUp start={0} end={120} duration={2} delay={0} />
                  )}
                  +
                </TitleInfo>
                <NoteInfo>Kampus sudah bekerjasama</NoteInfo>
              </Col>
              <Col lg={4} md={4} className="text-center my-lg-0 my-3">
                <TitleInfo>
                  {counterOn && (
                    <CountUp start={100} end={1200} duration={2} delay={0} />
                  )}
                  +
                </TitleInfo>
                <NoteInfo>Anggota tersebar diseluruh indonesia</NoteInfo>
              </Col>
              <Col lg={4} md={4} className="text-center my-lg-0 my-3">
                <TitleInfo>
                  {counterOn && (
                    <CountUp start={0} end={100} duration={2} delay={0} />
                  )}
                  +
                </TitleInfo>
                <NoteInfo>Materi pembelajaran telah disusun</NoteInfo>
              </Col>
            </div>
          </ScrollTrigger>
        ) : !section && pagination ? (
          <div className="mb-5 pb-5">
            {props.pageCount !== 0 && (
              <Pagination
                pageCount={props.pageCount}
                forcePage={props.forcePage}
                onPageChange={props.onPageChange}
              />
            )}
          </div>
        ) : (
          <Row className=" w-100 justify-content-center align-items-center">
            <Col lg={9} md={8} sm={12} className="text-center">
              <Title>Belajar Sekarang Bersama Alter!</Title>
            </Col>

            <Col
              lg={3}
              md={4}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Link to="/studies">
                <ButtonFooter>Lihat Materi</ButtonFooter>
              </Link>
            </Col>
          </Row>
        )}
      </div>
      <Container className="p-0 footer-bottom h-100 mb-lg-5">
        <section className="container-fluid container-footer-component row">
          <Col lg={3} md={6} className="text-start">
            <section className="logo-content">
              <img src={Logo} alt="logo" />
              <div>
                <p className="bottom-logo">
                  PT Sahabat Alter Indonesia adalah penyedia layanan
                  pembelajaran ilmu kesahatan berbasis digital.
                </p>
              </div>
            </section>
          </Col>

          <Col lg={3} md={6}>
            <section>
              <h1 className="heading-footer-component">Social Media</h1>
              <article>
                {dataSocialMedia.map((item) => {
                  return (
                    <a
                      key={item.id}
                      href={item.path}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="text-decoration-none"
                    >
                      <div
                        key={item.id}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="pe-1">
                          <img
                            src={item.icon}
                            width={23}
                            height={23}
                            alt="social"
                          />
                        </div>
                        <div className="link-footer-component" to={item.link}>
                          {item.name}
                        </div>
                      </div>
                    </a>
                  );
                })}
              </article>
            </section>
          </Col>

          <Col lg={2} md={6}>
            <section className="section-lainnya">
              <h1 className="heading-footer-component">Lainnya</h1>
              <article>
                {DataFooterShortcut.map((item) => {
                  return (
                    <div key={item.id} className="dataLain">
                      {item.key === "l" ? (
                        <Link className="link-footer-component" to={item.link}>
                          {item.title}
                        </Link>
                      ) : (
                        <a
                          className="link-footer-component"
                          href={item.link}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          {item.title}
                        </a>
                      )}
                    </div>
                  );
                })}
              </article>
            </section>
          </Col>

          <Col lg={3} md={6}>
            <section className="before-address-footer-component ">
              <h1 className="heading-footer-component">Contact</h1>
              <article className="address-footer-component">
                <p>
                  Jalan Barata Jaya III No.659, Karang Tengah, Kec. Karang Tengah, Kota Tangerang, Banten 15157
                </p>
                <div className="d-flex flex-column">
                  <a
                    href="tel:021-21260529"
                    target="_blank"
                    rel="noreferrer nopenner"
                    className="d-flex align-items-center text-decoration-none text-black"
                  >
                    <img src={Phone} width={23} height={23} alt="email" />:
                    021-21260529
                  </a>
                  <a
                    href="mailto:info@sahabatalter.com"
                    target="_blank"
                    rel="noreferrer nopenner"
                    className="tw-flex text-decoration-none text-black tw-w-full"
                  >
                    <img src={Email} width={23} height={23} alt="email" />:
                    info@sahabatalter.com
                  </a>
                </div>
              </article>
            </section>
          </Col>

          <Col className="d-lg-none d-md-none d-flex justify-content-center align-items-center mt-4">
            <p className="text-black copyright">
              © Copyright {year} By Sahabat Alter Indonesia
            </p>
          </Col>
        </section>
      </Container>

      <section className="container-copyright-footer-component mt-lg-5 d-lg-block d-md-block d-none">
        <article>
          <p className="copyright">© Copyright {year} By Sahabat Alter Indonesia</p>
        </article>
      </section>
    </FooterStyled>
  );
};

export default FooterCustom;
