import styled from "styled-components";
import { Col, Container } from "reactstrap";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: cover;
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top -10px center;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 768px) {
    font-size: 38px;
    line-height: 52px;
  }

  @media screen and (max-width: 430px) {
    font-size: 30px;
    line-height: 42px;
  }

  @media screen and (max-width: 380px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Note = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;

  @media screen and (max-width: 840px) {
    width: 60%;
    font-size: 22px;
  }

  @media screen and (max-width: 430px) {
    width: 90%;
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (max-width: 380px) {
    width: 80%;
    font-size: 18px;
  }
`;

export const SectionStudies = styled(Col)`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  border: 1px solid #F9FBFE;
  padding: 0px 0px 20px 0px;

  /* Shadow */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

  div h4 {
    color: black;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
  }
  div h5 {
    color: #6e6e6e;
    font-size: 16px;
    text-transform: capitalize;
  }

  @media screen and (max-width: 840px) {
    width: 100%;
  }
`;

export const CardStudies = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background: url(${(props) => props.background}) #fff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 20px 20px 0px 0px;

  @media screen and (max-width: 840px) {
    width: 100%;
    height: 450px;
    background-size: cover;
    margin-bottom: 10px;
    padding: 0;
  }
`;
