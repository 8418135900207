import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Banner, ButtonCustom, Diskon, TitleInfo } from "./StudiesStyle";
import { toast } from "react-toastify";
import Slider from "react-slick";

import axios from "helpers/GatewayService";
import { API_URL } from "helpers/ENVState";
import { getDetailsUkom, getLinkCheckout } from "redux/slices/Materi";
import { getTestimonials } from "redux/slices/Testimonials";

import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import CarouselImage from "assets/images/timPenyusun/carousel-team.png";
import ModalLogin from "components/organisms/ModalLogin";

import { Title, CardRattings } from "pages/Home/HomeStyles";

import Testi1 from "assets/images/testimoni/testi1.jpeg";
import Testi2 from "assets/images/testimoni/testi3.jpeg";
import Testi3 from "assets/images/testimoni/testi2.jpeg";
import Dinia from "assets/images/testimoni/dinia-ratih.jpg";
import Eka from "assets/images/testimoni/eka-putri-suhantora.jpg";
import Herlisa from "assets/images/testimoni/her-lisa.jpg";
import Nur from "assets/images/testimoni/nur-afiza.jpg";

const Cerita = [
  {
    id: 1,
    name: "Ira",
    image: Testi1,
    univ: "Akbid Respati Tasikmalaya",
    prodi: "Kebidanan",
    pesan:
      "Alhamdulillah setelah 4x belum lulus Uji Kompetensi bisa lulus uji kompetensi kebidanan berkat ikut bimbingan tim pengajar Alter Indonesia",
  },
  {
    id: 2,
    name: "Ramadhan",
    image: Testi2,
    univ: "Poltekkes Kemenkes Tasikmalaya",
    prodi: "Keperawatan",
    pesan:
      "Alhamdulilah materi2nya passs sma kurikulum, bahkan materi2 yg gk ada d kampus ada d alter jadi enak bgt buat persiapan persentasi tugas, apalgi materi dan video sop sangat membantu di jaman kuliah onlen ini kak, dan yg paling sya suka sih daftar obat ituuu komplittt jdi gak perlu bolai balik buka google, apalgi bayar sekali bisa buat di laptop dan hp hehehe, oiya smoga nanti latihan ujikom d alter juga bisa membantu saya insha Allah tahun depan amminnn",
  },
  {
    id: 3,
    name: "Mumtaz Wahyu",
    image: Testi3,
    univ: "Universitas Jenderal Soedirman",
    prodi: "Kedokteran",
    pesan:
      "Alter materinya sangat lengkap dan terbaru, dari semua materi yang ada di aplikasi semua bisa digunakan di sistem pembelajar anak kedokteran sehingga sangat memudahkan materi-materi untuk pembelajaran kedokteran, mencarinya juga gampang karena di sesuaikan dengan silabus pembelajaran FK",
  },
  {
    id: 4,
    name: "Dinia Rati Astuti",
    image: Dinia,
    univ: "Universitas Muhammadiyah Riau",
    prodi: "Keperawatan",
    pesan: "Bagus, Menarik, Memudahkan dalam mencari materi pembelajaran.",
  },
  {
    id: 5,
    name: "Nur Afiza",
    image: Nur,
    univ: "Universitas Muhammadiyah Riau",
    prodi: "Keperawatan",
    pesan:
      "Ramah, penjelasannya jelas, aplikasinya akan banyak membantu saya kedepannya",
  },
  {
    id: 6,
    name: "Herlisa Telaumbanua",
    image: Herlisa,
    univ: "Universitas Muhammadiyah Riau",
    prodi: "Keperawatan",
    pesan: "Sangat bagus, Terima kasih Alter.",
  },
  {
    id: 7,
    name: "Eka Putri Suhantora",
    image: Eka,
    univ: "Universitas Muhammadiyah Riau",
    prodi: "Keperawatan",
    pesan:
      "Aplikasi bagus banget bagi saya yang suka males nyari materi keperawatan di berbagai internet yang menurut saya ribet, jadi ini membantu dan sangat bermanfaat sekali bagi saya. Semoga makin bagus lagi kedepannya.",
  },
];

const DetailsStudies = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { materi, isLoadingMateri } = useSelector((state) => state.materis);

  const { banners } = useSelector((state) => state.banners);

  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);
  const [testimoniValue, setTestimoniValue] = useState([...Cerita]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [state, setState] = useState({
    title: null,
    about: null,
    includes: null,
    price: null,
    price_discount: null,
    grand_total: null,
    discount: null,
    name_discount: null,
    id_youtube: null,
    picture: null,
  });

  const { testimonials, isLoading } = useSelector(
    (state) => state.testimonials
  );

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "View Materi");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (id) {
      dispatch(getTestimonials());
      dispatch(getDetailsUkom(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!isLoadingMateri && materi) {
      setState((prev) => ({
        title: materi.name,
        about: materi.tentang,
        includes: materi.includes,
        price: materi.price,
        id_youtube: materi.link_preview,
        grand_total: materi.grand_total,
        picture: materi.picture,
        name_discount:
          materi.name_discount ||
          materi.name_discount !== "null" ||
          materi.name_discount !== "undefined"
            ? materi.name_promo
            : null,
        discount:
          materi.discount ||
          materi.name_discount !== "null" ||
          materi.name_discount !== "undefined"
            ? materi.discount
            : null,
        price_discount:
          materi.price_discount ||
          materi.name_discount !== "null" ||
          materi.name_discount !== "undefined"
            ? materi.price_discount
            : null,
      }));
    }
  }, [isLoadingMateri, materi]);

  useEffect(() => {
    if (!isLoading && testimonials.length !== 0) {
      let temp = [...testimoniValue];
      testimonials.forEach((element) => {
        temp.push({
          id: element.id,
          name: element.user.first_name + " " + element.user.last_name,
          image: `${API_URL}/${element.user.image}`,
          univ: element.user.university,
          prodi: element.user.faculty,
          pesan: element.testimoni,
        });
      });
      setTestimoniValue([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonials, isLoading]);

  function fCurrency(number) {
    if (number) {
      const reverse = number.toString().split("").reverse().join("");
      let ribuan = reverse.match(/\d{1,3}/g);
      const result = `Rp. ${ribuan.join(".").split("").reverse().join("")}`;
      return result;
    }
  }

  const toggleModalLogin = () => setModal(!modal);

  const handleCheckout = () => {
    const user = Cookies.get("user");
    if (user) {
      setModalAlert(!modalAlert);
    }
    if (!user) {
      toggleModalLogin();
    }
  };

  const handleSubmit = async (payload) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.post(API_URL + "/api/login", payload);

    if (!response.data.error) {
      const { accessToken, user } = response.data.result;

      const session = {
        token: accessToken,
        user,
      };
      Cookies.set("user", JSON.stringify(session), {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 30,
      });
    }
  };

  const handleCheckoutSubmit = async () => {
    setLoading(true);
    const payload = {
      amount: parseInt(materi.grand_total),
      name_class: materi.name,
      id_class: materi.id,
      type: materi.type,
    };
    const resp = await getLinkCheckout(payload);

    if (resp.status === "success") {
      setLoading(false);
      window.open(resp.result, "_blank");
      setModalAlert(!modalAlert);
    }
    if (resp.response.data.status === "failed") {
      setLoading(false);
      return toast.error(resp.response.data.message);
    }
    setLoading(false);
  };

  const settingsCerita = {
    infinite: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          rows: 1,
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 840,
        settings: {
          rows: 1,
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          rows: 1,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <SEO
        title="Materi Sahabat Alter Indonesia"
        description="Alter merupakan aplikasi pembelajaran yang berisi materi perkuliahan kesehatan dalam bentuk teks dan video yang interaktif."
        keywords="Kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi, Ilmu Gizi, Sahabat Alter, PT Sahabat Alter Indonesia, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>

      {/* Teams Alter */}
      <Container className="my-5">
        <Row>
          <Col lg={6} className="py-5 d-flex justify-content-center">
            <img src={`${API_URL}/${state.picture}`} alt="" />
          </Col>
          <Col lg={6} className="my-5">
            <Row className="w-100 flex-wrap">
              <Col
                lg={12}
                className={`${
                  state.name_discount ? "d-flex" : "d-none"
                } justify-content-start`}
              >
                <Diskon>
                  <span className="text-uppercase">{`${state.name_discount}`}</span>
                </Diskon>
              </Col>
              <Col lg={12} className="d-flex flex-column justify-content-start">
                <TitleInfo>Tentang Kelas {state.title}</TitleInfo>
                <span
                  dangerouslySetInnerHTML={{
                    __html: state.about,
                  }}
                />
              </Col>
              <Col lg={12} className="d-flex flex-column justify-content-start">
                <TitleInfo>Sudah Termasuk</TitleInfo>
                <span
                  dangerouslySetInnerHTML={{
                    __html: state.includes,
                  }}
                />
              </Col>
              <Col lg={12} className="w-100">
                <Row className="align-items-center">
                  <Col lg={6} className="d-flex flex-column">
                    {state.price_discount && (
                      <span className="fw-bold price-discount">
                        {fCurrency(state.price)}
                      </span>
                    )}
                    <span className="fw-bold price-normal">
                      {fCurrency(state.grand_total)}
                    </span>
                  </Col>
                  <Col lg={6}>
                    <ButtonCustom onClick={handleCheckout}>
                      Beli Sekarang
                    </ButtonCustom>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center">
          <Col
            lg={11}
            md={11}
            sm={11}
            className="d-flex flex-column justify-content-center align-items-center "
          >
            <Title>Cerita Sahabat Alter</Title>
          </Col>
          <Col lg={11} md={11} sm={11} className="mt-3">
            <Row className="container-fluid">
              <Slider {...settingsCerita}>
                {testimoniValue.map((data, i) => (
                  <Col
                    lg={3}
                    md={3}
                    key={data.id}
                    className="d-flex flex-column justify-content-center align-items-center h-100 p-2"
                  >
                    <CardRattings>
                      <img src={data.image} alt="profile" />
                      <h5>{data.name}</h5>
                      <span>
                        {data.univ} | {data.prodi}
                      </span>
                      <div className="caption">
                        <span className="">"{data.pesan}"</span>
                      </div>
                    </CardRattings>
                  </Col>
                ))}
              </Slider>
            </Row>
          </Col>
        </Row>
      </Container>

      <FooterCustom section={true} />

      <ModalLogin
        modalLogin={modal}
        setModalLogin={setModal}
        toggleModalLogin={toggleModalLogin}
        handleSubmit={handleSubmit}
      />

      <Dialog
        open={modalAlert}
        onClose={() => setModalAlert(!modalAlert)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin ingin membeli kelas ini?
        </DialogTitle>
        <DialogContentText sx={{ textAlign: "center", my: 2 }}>
          Anda akan diarahkan ke halaman xendit.
        </DialogContentText>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button color="inherit" onClick={() => setModalAlert(!modalAlert)}>
            Batal
          </Button>
          <LoadingButton
            color="success"
            onClick={handleCheckoutSubmit}
            autoFocus
            loading={loading}
          >
            Yakin
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailsStudies;
