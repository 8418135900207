import Marquee from "react-fast-marquee";

import Univ1 from 'assets/images/home/partnershipLogo/univ_1.webp';
import Univ2 from 'assets/images/home/partnershipLogo/univ_2.webp';
import Univ3 from 'assets/images/home/partnershipLogo/univ_3.webp';
import Univ4 from 'assets/images/home/partnershipLogo/univ_4.webp';
import Univ5 from 'assets/images/home/partnershipLogo/univ_5.webp';
import Univ6 from 'assets/images/home/partnershipLogo/univ_6.webp';
import Univ7 from 'assets/images/home/partnershipLogo/univ_7.webp';
import Univ8 from 'assets/images/home/partnershipLogo/univ_8.webp';
import Univ9 from 'assets/images/home/partnershipLogo/univ_9.webp';
import Univ10 from 'assets/images/home/partnershipLogo/univ_10.webp';
import Univ11 from 'assets/images/home/partnershipLogo/univ_11.webp';
import Univ12 from 'assets/images/home/partnershipLogo/univ_12.webp';
import Univ13 from 'assets/images/home/partnershipLogo/univ_13.webp';
import Univ14 from 'assets/images/home/partnershipLogo/univ_14.webp';
import Univ15 from 'assets/images/home/partnershipLogo/univ_15.webp';
import Univ16 from 'assets/images/home/partnershipLogo/univ_16.webp';
import Univ17 from 'assets/images/home/partnershipLogo/univ_17.webp';
import Univ18 from 'assets/images/home/partnershipLogo/univ_18.webp';
import Univ19 from 'assets/images/home/partnershipLogo/univ_19.webp';
import Univ20 from 'assets/images/home/partnershipLogo/univ_20.webp';
import Univ21 from 'assets/images/home/partnershipLogo/univ_21.webp';

const InfiniteScrollComponent = () => {
    return (
        <div className="tw-flex tw-flex-col tw-gap-10">
            <Marquee play={true} pauseOnHover direction="right" speed={10} delay={0} loop={0} autoFill={true} gradientColor="rgb(248, 251, 253)">
                <img src={Univ1} alt="logo1" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ2} alt="logo2" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ3} alt="logo3" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ4} alt="logo4" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ5} alt="logo5" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
            </Marquee>
            <Marquee play={true} pauseOnHover direction="left" speed={10} delay={0} loop={0} autoFill={true} gradientColor="rgb(248, 251, 253)">
                <img src={Univ11} alt="logo11" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ12} alt="logo12" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ13} alt="logo13" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ14} alt="logo14" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ15} alt="logo15" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ16} alt="logo16" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ17} alt="logo17" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ18} alt="logo18" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ19} alt="logo19" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ20} alt="logo20" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ21} alt="logo21" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
            </Marquee>
            <Marquee play pauseOnHover direction="right" speed={10} delay={0} loop={0} autoFill={true} className="tw-flex tw-items-center tw-gap-10" gradientColor="rgb(248, 251, 253)">
                <img src={Univ10} alt="logo10" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ9} alt="logo9" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ8} alt="logo8" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ7} alt="logo7" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
                <img src={Univ6} alt="logo6" className="tw-w-[100px] tw-h-[100px] tw-object-contain tw-mx-6" />
            </Marquee>
        </div>
    )
};

export default InfiniteScrollComponent;