/* eslint-disable no-undef */
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "./ENVState";

/**
 * Create axios
 */
const axiosClient = axios.create({
  // withCredentials: true,
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
  },
});

/**
 * Request interceptor. Runs before your request reaches the server
 *
 * @param {any} config
 * @returns {any}
 */
const onRequest = (config) => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // Not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // Proceed with the initial response

  // If token not null, add token to header
  if (localStorage.getItem("user") !== undefined) {
    // initialize token
    const token = localStorage.getItem("token");

    // add token to header
    config.headers.Authorization = `Bearer ${token}`;
  }

  // If token null, remove token from header
  if (localStorage.getItem("user") === undefined) {
    // remove token from header
    delete config.headers.Authorization;
  }

  return config;
};

/**
 * Function for handle error by status
 *
 * @param {number} status
 * @returns {string}
 */
const handleError = (status, message) => {
  let errorMessage;
  switch (status) {
    case 400:
      errorMessage = message ?? "Bad request";
      break;
    case 401:
      errorMessage = message ?? "Unauthorized";
      break;
    case 403:
      errorMessage =
        message ?? "Access denied, you are not authorized to access this page";
      break;
    case 404:
      errorMessage = message ?? "The requested resource could not be found";
      break;
    case 429:
      errorMessage = message ?? "Too many requests";
      break;
    case 500:
      errorMessage = message ?? "Internal Server Error";
      break;
    case 498:
      errorMessage = message ?? "Token expired";
      break;
    default:
      errorMessage = message ?? "An error occurred, please try again later";
      break;
  }
  return errorMessage;
};

// attach request
axiosClient.interceptors.request.use(onRequest, null);

// attach response
axiosClient.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    // Initialize error message
    // If error has response, show error message
    if (error?.response?.data !== undefined && error?.response?.data !== null) {
      toast.error(
        handleError(error?.response?.status, error?.response?.data?.message)
      );
    } else {
      toast.error("An error occurred, please try again later");
    }

    // if unauthorized, redirect to login page
    setTimeout(async () => {
      if (error?.response?.status === 401) {
        localStorage.clear();

        window.location.href = "/login";
      }
    }, 3000);

    return await Promise.reject(error);
  }
);

export default axiosClient;
