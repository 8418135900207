import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Banner, TitleInfo, DescInfo } from "./StudiesStyle";
import { toast } from "react-toastify";

import axios from "helpers/GatewayService";
import { API_URL } from "helpers/ENVState";
import { getDetailsMateri, getLinkCheckout } from "redux/slices/Materi";
import { getTestimonials } from "redux/slices/Testimonials";

import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import CarouselImage from "assets/images/timPenyusun/carousel-team.png";
import ModalLogin from "components/organisms/ModalLogin";
import InfiniteScrollTesti from "pages/Home/Components/Testimony";

import Benefit1 from "assets/images/materi/ourBenefit/1-tidak-berlangganan.webp";
import Benefit2 from "assets/images/materi/ourBenefit/2-free-updates.webp";
import Benefit3 from "assets/images/materi/ourBenefit/3-garansi-akses.webp";
import Benefit4 from "assets/images/materi/ourBenefit/4-request-e-Journal.webp";
import Disclamer from "assets/images/materi/disclaimer.webp";
import Term from "assets/images/materi/syarat.webp";
import _, { parseInt } from "lodash";

const DetailsStudies = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { materi, isLoadingMateri } = useSelector((state) => state.materis);
  const { banners } = useSelector((state) => state.banners);

  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [state, setState] = useState({
    title: null,
    about: null,
    includes: null,
    price: null,
    price_discount: null,
    grand_total: null,
    discount: null,
    name_discount: null,
    id_youtube: null,
    banners: null
  });

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "View Materi");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (id) {
      dispatch(getTestimonials());
      dispatch(getDetailsMateri(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!isLoadingMateri && materi) {
      let temp_banners = [];
      if (materi.banner_materis.length > 0) {
        materi.banner_materis.forEach(element => {
          temp_banners.push({
            id: element.id,
            serial_number: parseInt(element.serial_number),
            image: `${API_URL}/${element.picture}`
          })
        });
      }
      setState((prev) => ({
        title: materi.name,
        about: materi.tentang,
        includes: materi.includes,
        price: materi.price,
        id_youtube: materi.link_preview,
        grand_total: materi.grand_total,
        name_discount:
          materi.name_discount ||
            materi.name_discount !== "null" ||
            materi.name_discount !== "undefined"
            ? materi.name_promo
            : null,
        discount:
          materi.discount ||
            materi.name_discount !== "null" ||
            materi.name_discount !== "undefined"
            ? materi.discount
            : null,
        price_discount:
          materi.price_discount ||
            materi.name_discount !== "null" ||
            materi.name_discount !== "undefined"
            ? materi.price_discount
            : null,
        banners: materi.banner_materis.length > 0 ? _.sortBy(temp_banners, ["serial_number"]) : [
          {
            id: materi.id,
            image: `${API_URL}/${materi.picture}`,
            serial_number: 1,
          }
        ]
      }));
    }
  }, [isLoadingMateri, materi]);

  function fCurrency(number) {
    if (number) {
      const reverse = number.toString().split("").reverse().join("");
      let ribuan = reverse.match(/\d{1,3}/g);
      const result = `Rp. ${ribuan.join(".").split("").reverse().join("")}`;
      return result;
    }
  }

  const toggleModalLogin = () => setModal(!modal);

  const handleCheckout = () => {
    const user = Cookies.get("user");
    if (user) {
      setModalAlert(!modalAlert);
    }
    if (!user) {
      toggleModalLogin();
    }
  };

  const handleSubmit = async (payload) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.post(API_URL + "/api/login", payload);

    if (!response.data.error) {
      const { accessToken, user } = response.data.result;

      const session = {
        token: accessToken,
        user,
      };
      Cookies.set("user", JSON.stringify(session), {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 30,
      });
    }
  };

  const handleCheckoutSubmit = async () => {
    setLoading(true);
    const payload = {
      amount: parseInt(materi.grand_total),
      name_class: materi.name,
      id_class: materi.id,
      type: materi.type,
    };
    const resp = await getLinkCheckout(payload);

    if (resp.status === "success") {
      setLoading(false);
      window.open(resp.result, "_blank");
      setModalAlert(!modalAlert);
    }
    if (resp.response.data.status === "failed") {
      setLoading(false);
      return toast.error(resp.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <SEO
        title="Materi Sahabat Alter Indonesia"
        description="Alter merupakan aplikasi pembelajaran yang berisi materi perkuliahan kesehatan dalam bentuk teks dan video yang interaktif."
        keywords="Kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi, Ilmu Gizi, Sahabat Alter, PT Sahabat Alter Indonesia, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>

      <section className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-12">
        <div className="xl:tw-w-[1280px] tw-w-full xl:tw-px-0 tw-px-10 tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 md:tw-py-[80px] tw-py-[40px] tw-gap-5">
          <div className="tw-relative tw-w-full">
            <Swiper
              modules={[Navigation]}
              autoplay
              navigation
              loop
              spaceBetween={0}
              slidesPerView={1}
            >
              {state.banners && state.banners.length > 0 && state.banners.map((d) => (
                <SwiperSlide key={d.id}>
                  <img src={d.image} alt="" className="tw-w-full tw-rounded-xl tw-h-auto" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div
            className="tw-w-full tw-flex tw-flex-col tw-gap-5 tw-rounded-xl tw-p-5 tw-shadow-md tw-shadow-gray-400"
          >
            <div className="tw-flex tw-flex-col tw-gap-3">
              <TitleInfo className="tw-text-[32px] tw-font-bold tw-leading-[38px]">{state.title}</TitleInfo>
              <DescInfo
                className="w-100"
                style={{
                  textAlign: 'justify'
                }}
                dangerouslySetInnerHTML={{
                  __html: state.about,
                }}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <TitleInfo className="tw-text-[32px] tw-font-bold tw-leading-[38px]">Sudah Termasuk</TitleInfo>
              <span
                dangerouslySetInnerHTML={{
                  __html: state.includes,
                }}
              />
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="xl:tw-w-[1280px] tw-w-full tw-px-10 xl:tw-px-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-w-full tw-flex tw-flex-col tw-gap-5 xl:tw-flex-row md:tw-justify-between">
            <div className="tw-w-full tw-flex tw-flex-col">
              <h2 className="tw-text-[#1D2B4F] md:tw-text-5xl tw-text-4xl xl:tw-leading-[60.34px] tw-font-bold tw-italic">Lets Go Digital</h2>
              <span className="tw-text-[#1D2B4F] md:tw-text-4xl tw-text-2xl xl:tw-leading-[46.51px] tw-text-normal">Kompeten dengan Cara Baru</span>
            </div>
          </div>
          <div className="tw-w-full tw-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-grid-rows-2 xl:tw-grid-flow-col tw-gap-5">
            <div className="tw-w-full tw-flex tw-items-center tw-gap-3">
              <div className="tw-w-[160px] tw-h-[160px]">
                <img src={Benefit1} alt="" className="tw-rounded-xl" />
              </div>
              <div className="tw-w-[420px] tw-w-flex tw-flex-col tw-gap-3 tw-text-justify">
                <h3 className="tw-text-[#323B4B] tw-text-2xl tw-font-semibold tw-leading-9">Tidak Berlangganan</h3>
                <span className="tw-text-[#5D6A83] tw-text-base">Pengguna hanya perlu melakukan pembayaran diawal sekali saja. Tanpa ada biaya tambahan langganan baik bulanan maupun tahunan untuk mengakses kelas yang sudah dimiliki.</span>
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-items-center tw-gap-3">
              <div className="tw-w-[160px] tw-h-[160px] ">
                <img src={Benefit2} alt="" className="tw-rounded-xl" />
              </div>
              <div className="tw-w-[420px] tw-w-flex tw-flex-col tw-gap-3 tw-text-justify">
                <h3 className="tw-text-[#323B4B] tw-text-2xl tw-font-semibold tw-leading-9">Bebas Biaya Pembaruan</h3>
                <span className="tw-text-[#5D6A83] tw-text-base">Pengguna akan mendapatkan fasilitas pembaruan materi secara otomatis dan berkala tanpa ada penambahan biaya.</span>
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-items-center tw-gap-3">
              <div className="tw-w-[160px] tw-h-[160px]">
                <img src={Benefit3} alt="" className="tw-rounded-xl" />
              </div>
              <div className="tw-w-[420px] tw-w-flex tw-flex-col tw-gap-3 tw-text-justify">
                <h3 className="tw-text-[#323B4B] tw-text-2xl tw-font-semibold tw-leading-9">Garansi Akses</h3>
                <span className="tw-text-[#5D6A83] tw-text-base">Pengguna akan mendapatkan garansi akses untuk mempelajari materi kelas digital yang sudah tersedia.</span>
              </div>
            </div>
            <div className="tw-w-full tw-flex tw-items-center tw-gap-3">
              <div className="tw-w-[160px] tw-h-[160px]">
                <img src={Benefit4} alt="" className="tw-rounded-xl" />
              </div>
              <div className="tw-w-[420px] tw-w-flex tw-flex-col tw-gap-3 tw-text-justify">
                <h3 className="tw-text-[#323B4B] tw-text-2xl tw-font-semibold tw-leading-9 tw-italic">Request Journal</h3>
                <span className="tw-text-[#5D6A83] tw-text-base">Pengguna mendapatkan fasilitas request jurnal yang akan dicarikan oleh penyedia tanpa ada biaya tambahan.</span>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:tw-w-[1280px] tw-w-full tw-flex tw-flex-col tw-gap-10 tw-items-start tw-justify-between md:tw-py-[80px] tw-py-[40px] tw-relative tw-px-10 xl:tw-px-0">
          <img
            src={Disclamer}
            alt=""
            className="tw-rounded-lg tw-cursor-pointer tw-z-10"
          />
          <img
            src={Term}
            alt=""
            className="tw-rounded-lg tw-cursor-pointer tw-z-10"
          />
        </div>

        <div
          className="xl:tw-w-[1280px] tw-w-full tw-my-[40px] xl:tw-mx-0 tw-mx-10 tw-flex tw-flex-col xl:tw-flex-row tw-justify-center tw-items-center tw-gap-10 tw-bg-white tw-border xl:tw-py-5 xl:tw-px-10 tw-rounded-xl tw-py-5 tw-px-5"
          style={{
            boxShadow: '1px 3px 3px 1px rgba(0, 0, 0, 0.09)'
          }}
        >
          <div className="xl:tw-w-2/3 tw-w-full">
            <TitleInfo className="tw-text-[32px] tw-font-bold tw-leading-[38px]">{state.title}</TitleInfo>
          </div>
          <div className="xl:tw-w-1/3 tw-w-full tw-flex tw-items-end tw-flex-col tw-gap-5">
            {state.price_discount && (
              <div className="tw-flex tw-items-center tw-gap-3">
                <span className="tw-text-[#323B4B] tw-text-lg tw-font-semibold">Diskon</span>
                <span className="tw-font-bold price-discount">
                  {fCurrency(state.price)}
                </span>
              </div>
            )}
            <div className="tw-flex tw-items-center tw-gap-3">
              <span className="tw-text-[#323B4B] tw-text-lg tw-font-semibold">Harga Normal</span>
              <span className="tw-font-bold price-normal">
                {fCurrency(state.grand_total)}
              </span>
            </div>
            <button className="tw-w-full tw-h-[50px] tw-rounded-xl tw-border-0 tw-bg-[#169E8C] tw-text-white hover:tw-bg-transparent hover:tw-text-[#169E8C] hover:tw-border hover:tw-border-[#169E8C]" onClick={handleCheckout}>
              <span className="tw-font-bold tw-text-xl">
                Beli Sekarang
              </span>
            </button>
          </div>
        </div>

        {/* Testimony */}
        <div className="tw-w-full md:tw-py-[80px] tw-py-[40px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-10 tw-bg-[#E4E7EA]">
          <div className="tw-w-[410px] tw-flex tw-flex-col tw-justify-center tw-items-center">
            <h3 className="tw-text-[#4043FF] tw-font-bold tw-text-sm tw-leading-4 tw-tracking-[5px]">REVIEWS</h3>
            <h2 className="tw-text-[#1D2B4F] tw-text-4xl tw-tracking-[-0.5px] tw-text-center tw-font-bold">Why other people love to use our product</h2>
          </div>
          <div className="xl:tw-w-[1280px] tw-w-full">
            <InfiniteScrollTesti />
          </div>
        </div>
      </section>

      <FooterCustom section={true} />

      <ModalLogin
        modalLogin={modal}
        setModalLogin={setModal}
        toggleModalLogin={toggleModalLogin}
        handleSubmit={handleSubmit}
      />

      <Dialog
        open={modalAlert}
        onClose={() => setModalAlert(!modalAlert)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin ingin membeli kelas ini?
        </DialogTitle>
        <DialogContentText sx={{ textAlign: "center", my: 2 }}>
          Anda akan diarahkan ke halaman xendit.
        </DialogContentText>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button color="inherit" onClick={() => setModalAlert(!modalAlert)}>
            Batal
          </Button>
          <LoadingButton
            color="success"
            onClick={handleCheckoutSubmit}
            autoFocus
            loading={loading}
          >
            Yakin
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DetailsStudies;
