import styled from "styled-components";
import { Button, Container } from "reactstrap";
import DiskonBg from "assets/icons/diskon.svg";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: cover;
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top -10px center;
  }
`;

export const ButtonCustom = styled(Button)`
  width: 200px;
  height: 50px;
  background: #eeeb4b !important;
  border-radius: 10px;
  border: none;

  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  color: #0f7ca1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const Diskon = styled.div`
  width: 200px;
  height: 67px;
  background-image: url(${DiskonBg});
  background-repeat: no-repeat;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
  }
`;

export const TitleInfo = styled.h2`
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const DescInfo = styled.div`
  p {
    margin-bottom: 0 !important;
  }
`;
