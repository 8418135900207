import { combineReducers } from "redux";

import articlesReducers from "./slices/Articles";
import bannersReducers from "./slices/Banner";
import materiReducers from "./slices/Materi";
import teamsReducers from "./slices/Teams";
import testimonialsReducers from "./slices/Testimonials";
import ebooksReducers from "./slices/Ebooks";

const rootReducer = combineReducers({
  articles: articlesReducers,
  banners: bannersReducers,
  materis: materiReducers,
  teams: teamsReducers,
  testimonials: testimonialsReducers,
  ebooks: ebooksReducers
});

export { rootReducer };
