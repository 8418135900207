// @mui
import { TableRow, TableCell, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function MateriTableRow({ row }) {
    const navigate = useNavigate();
    const { id, name, image, author, tahun_terbit, penerbit } = row;

    const handleConverUrl = (id) => {
        let url = "#";
        let modifiedString = name
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .toLowerCase();
        modifiedString = name.replace(/\s+/g, "-");
        url = `/ebooks/details/${modifiedString}/${id}`;
        navigate(url);
    };

    return (
        <TableRow sx={{ cursor: 'pointer', border: 'none' }} 
        onClick={() => handleConverUrl(id)}>
            <TableCell
                sx={{ display: 'flex', alignItems: 'center', border: 'none' }}
            >
                <img
                    src={image}
                    alt={name}
                    style={{
                        borderRadius: 1.5,
                        width: 50,
                        height: 60,
                    }}
                />
                <Typography variant="subtitle2" sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: '600', pl: 1 }} noWrap>
                    {name}
                </Typography>
            </TableCell>

            <TableCell sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: '500', border: 'none' }}>{author}</TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: '500', border: 'none' }}>{tahun_terbit}</TableCell>
            <TableCell align="left" sx={{ textTransform: 'capitalize', fontSize: '16px', fontWeight: '500', border: 'none' }}>{penerbit}</TableCell>
        </TableRow>
    );
}
