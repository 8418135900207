import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { API_URL } from "helpers/ENVState";

import { getAllTeams } from "redux/slices/Teams";

import SEO from "service";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import CarouselImage from "assets/images/timPenyusun/carousel-team.png";
import EmptyContent from "components/organisms/EmptyContent";

import {
  Banner,
  Title,
  Note,
  ButtonCustom,
} from "./teamStyle";

const Teams = () => {
  const dispatch = useDispatch();

  const { banners } = useSelector((state) => state.banners);
  const { teams, isLoading } = useSelector((state) => state.teams);

  const [state, setState] = useState({
    two: "tw-hidden",
    three: "tw-hidden",
  });
  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);
  const [teams1, setTeams1] = useState([]);
  const [teams2, setTeams2] = useState([]);
  const [teams3, setTeams3] = useState([]);
  const [teamsValue, setTeamsvalue] = useState([]);

  useEffect(() => {
    dispatch(getAllTeams());
  }, [dispatch]);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Tim Penyusun");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (!isLoading && teams.length !== 0) {
      let temp = [];
      teams.forEach((element) => {
        temp.push({
          id: element.id,
          name: element.name,
          special: element.special,
          image: `${API_URL}/${element.image}`,
        });
      });
      setTeamsvalue([...temp]);

      const partSize = 12; // Ukuran setiap bagian

      const part1 = temp.slice(0, partSize); // Bagian pertama
      const part2 = temp.slice(partSize, partSize * 2); // Bagian kedua
      const part3 = temp.slice(partSize * 2, partSize * 3); // Bagian ketiga

      setTeams1([...part1]);
      setTeams2([...part2]);
      setTeams3([...part3]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, isLoading]);

  const handleOther = () => {
    if (state.two === "tw-hidden" && state.three === "tw-hidden") {
      setState((prev) => ({
        ...prev,
        two: "",
      }));
    }

    if (state.two === "" && state.three === "tw-hidden") {
      setState((prev) => ({
        ...prev,
        three: "",
      }));
    }
  };
  return (
    <Fragment>
      <SEO
        title="Tim Penyusun Sahabat Alter Indonesia"
        description="Materi yang di sediakan disusun oleh dokter yang ahli di bidang nya materi kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi & Gizi yang efektif buat kamu belajar."
        keywords="kedokteran, kedokteran gigi, Keperawatan, Kebidanan, Farmasi, Gizi, Sahabat Alter, PT Sahabat Alter Indonesia"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>

      {/* Teams Alter */}
      <section className="tw-bg-[#f8fafc] tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center xl:tw-pt-[100px] tw-pt-[50px] tw-gap-20">
        <div
          className="tw-flex tw-flex-col tw-justify-center tw-items-center"
        >
          <Title>Tim Penyusun</Title>
          <Note>
            Materi yang kami sediakan di susun oleh ahli di bidang nya
          </Note>
        </div>
        <div className="xl:tw-w-[1280px] tw-w-full xl:tw-px-0 tw-px-10 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10 md:tw-py-[80px] tw-py-[40px]">
          <div className="tw-w-full tw-grid xl:tw-grid-cols-4 md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-5">
            {teams1.map((data) => (
              <div
                key={data.id}
                id="card-corp"
                className="group tw-w-full tw-px-3 tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-items-center tw-rounded-xl tw-cursor-default tw-bg-white hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C] tw-p-5"
              >
                <img src={data.image} alt="banner" className="tw-w-[185px] tw-h-[185px] tw-rounded-full tw-object-cover tw-shadow-lg tw-shadow-gray-400 tw-mb-3" />
                <h6 className="tw-m-0 tw-text-lg tw-font-semibold tw-text-center  tw-text-[#1D2B4F] group-hover:tw-text-white">
                  {data.name}
                </h6>
                <span className="tw-text-base tw-text-medium tw-text-center tw-text-[#798196] group-hover:tw-text-white">{data.special}</span>
              </div>
            ))}
            {teams2.map((data) => (
              <div
                key={data.id}
                id="card-corp"
                className={`${state.two} group tw-w-full tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center tw-rounded-xl tw-cursor-default tw-bg-white hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C] tw-p-5`}
              >
                <img src={data.image} alt="banner" className="tw-w-[185px] tw-h-[185px] tw-rounded-full tw-object-cover tw-shadow-lg tw-shadow-gray-400 tw-mb-3" />
                <h6 className="tw-text-lg tw-font-semibold tw-text-center  tw-text-[#1D2B4F] group-hover:tw-text-white">
                  {data.name}
                </h6>
                <span className="tw-text-base tw-text-medium tw-text-center tw-text-[#798196] group-hover:tw-text-white">{data.special}</span>
              </div>
            ))}
            {teams3.map((data, i) => (
              <div
                key={data.id}
                id="card-corp"
                className={`${state.three} group tw-w-full tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center tw-rounded-xl tw-cursor-default tw-bg-white hover:tw-bg-gradient-to-b hover:tw-from-[#3BE4CE] hover:tw-to-[#169E8C] tw-p-5`}
              >
                <img src={data.image} alt="banner" className="tw-w-[185px] tw-h-[185px] tw-rounded-full tw-object-cover tw-shadow-lg tw-shadow-gray-400 tw-mb-3" />
                <h6 className="tw-text-lg tw-font-semibold tw-text-center  tw-text-[#1D2B4F] group-hover:tw-text-white">
                  {data.name}
                </h6>
                <span className="tw-text-base tw-text-medium tw-text-center tw-text-[#798196] group-hover:tw-text-white">{data.special}</span>
              </div>
            ))}
          </div>
          <div>

          {(teams2.length !== 0 || teams3.length !== 0) &&
              state.three === "tw-hidden" ? (
              <div className="tw-w-full tw-flex tw-justify-center">
                <ButtonCustom onClick={handleOther}>
                  Lebih Banyak
                </ButtonCustom>
              </div>
            ) : null}
            {teamsValue.length === 0 && (
              <EmptyContent
                title="No Data"
                sx={{
                  "& span.MuiBox-root": { height: 160 },
                }}
              />
            )}
          </div>
        </div>
      </section>

      <FooterCustom section={true} />
    </Fragment>
  );
};

export default Teams;
