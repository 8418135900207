import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "helpers/GatewayService";
//
import { dispatch } from "redux/store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  teams: [],
};

const slice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET teams
    getTeamsSuccess(state, action) {
      state.isLoading = false;
      state.teams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllTeams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/teams");
      dispatch(slice.actions.getTeamsSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
