import { useRef, useState } from "react";
import video from "assets/images/home/materi-web.webm";
import Poster from "assets/images/home/dummy-video.png";
import PlayIcon from 'assets/icons/play.svg';

function App() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  return (
    <div className="container-video">
      <div className="playerContainer tw-relative">
        <video poster={Poster} className={`tw-w-full tw-rounded-2xl tw-relative ${isPlaying ? "tw-z-20" : ""}`} ref={videoRef} src={video} onClick={handlePlay} loop />
          <div className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-items-center tw-justify-center">
              {!isPlaying && (
                <button className="controlButton" onClick={handlePlay}>
                  <img src={PlayIcon} alt="play" className="tw-w-[96px] tw-h-[96px]" />
                </button>
              )}
          </div>
      </div>
    </div>
  );
}

export default App;
