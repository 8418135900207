import React from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";

import { URL_FE } from "helpers/ENVState";

const StyledButtonCustom = styled.a`
  width: 131px;
  height: fit-content;
  background: linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%)
      padding-box,
    linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%) border-box;
  border-radius: 50em;
  text-align: center;
  text-decoration: none;
  border: 2px solid transparent;
  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    align-items: center;
    text-align: center;
  }

  :hover {
    color: #ffffff;
  }
`;

const StyledButtonLogin = styled.div`
  cursor: pointer;
  width: 131px;
  height: fit-content;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(80.87deg, #05a390 16.49%, #1170a6 103.22%) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    align-items: center;
    text-align: center;
    background: -webkit-linear-gradient(
      80.87deg,
      #05a390 16.49%,
      #1170a6 103.22%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
const AuthNavigation = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        "& button:first-child": { mr: 2 },
      }}
    >
      <StyledButtonLogin
        // href={`${URL_FE}/auth/login`}
        // rel="noreferrer noopener"
        onClick={props.toggleModalLogin}
      >
        <span>Masuk</span>
      </StyledButtonLogin>
      <StyledButtonCustom
        href={`${URL_FE}/auth/register`}
        rel="noreferrer noopener"
      >
        <span>Daftar</span>
      </StyledButtonCustom>
    </Box>
  );
};

export default AuthNavigation;
