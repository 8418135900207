import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
} from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { API_URL } from "helpers/ENVState";
import { getArticlesWithPage } from "redux/slices/Articles";

import SEO from "service";
import EmptyContent from "components/organisms/EmptyContent";
import FooterCustom from "components/organisms/Layouts/FooterCustom";
import CarouselImage from "assets/images/head-banner-mobile.png";
import { ThreeDots } from "react-loader-spinner";
// import Article1 from "assets/images/khasiat.png";
// import Article2 from "assets/images/kopi.png";
// import Article3 from "assets/images/kuku.png";

export const Banner = styled(Container)`
  width: 100%;
  height: 80vh;
  background-image: url(${(props) => props.backgroundimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 840px) {
    background-size: cover;
    background-position: top center;
    height: 50vh;
  }

  @media screen and (max-width: 430px) {
    height: 30vh !important;
    background-size: cover;
    background-position: top -10px center;
  }
`;

const Capsul = styled.div`
  width: 120px;
  height: 40px;
  background: #eeeb4b;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #0f7ca1;
  margin-bottom: 10px;
`;

const ButtonArticle = styled(Button)`
  background: none;
  border: none;
  color: black;
  padding: 0;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  bottom: 0;
  :hover {
    background: none;
  }
`;

const CardArticle = styled(Card)`
  width: 90%;
  height: 450px;
  background: #ffffff;
  opacity: 0.9;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
  img {
    width: 100% !important;
    height: 220px;
    border-radius: 20px 20px 0 0;
  }

  @media screen and (max-width: 840px) {
    width: 300px;
    height: 400px;
  }

  @media screen and (max-width: 330px) {
    width: 250px;
    height: 400px;
  }
`;

const Articles = () => {
  const dispatch = useDispatch();

  const [carouselImageValue, setCarouselImageValue] = useState(CarouselImage);
  const [articleValue, setArticleValue] = useState([]);

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    total_page: 1,
    total_data: 0,
  });

  const { banners } = useSelector((state) => state.banners);
  const { articles_with_page, isLoadingArticle } = useSelector(
    (state) => state.articles
  );

  useEffect(() => {
    dispatch(getArticlesWithPage(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (banners.length !== 0) {
      const findData = banners.find((d) => d.name === "Artikel");
      if (findData) {
        setCarouselImageValue(`${API_URL}/${findData.banners[0].image}`);
      }
    }
  }, [banners]);

  useEffect(() => {
    if (
      !isLoadingArticle &&
      articles_with_page &&
      articles_with_page.length !== 0
    ) {
      let temp = [];
      articles_with_page.data.forEach((element) => {
        temp.push({
          id: element.id,
          value: element.id,
          image: `${API_URL}/${element.image}`,
          date: moment(element.updated_at).format("DD MMM"),
          title: element.title,
          button: "Selengkapnya...",
        });
      });
      setParams((prev) => ({
        ...prev,
        page: articles_with_page.page,
        total_page: articles_with_page.total_pages,
        total_data: articles_with_page.total_data,
      }));
      setArticleValue([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles_with_page, isLoadingArticle]);

  const handleConverUrl = (id, value) => {
    let url = "#";
    if (value) {
      let modifiedString = value.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
      modifiedString = modifiedString.replace(/\s+/g, "-");
      url = `/articles/details/${modifiedString}/${id}`;
    }
    return url;
  };

  const onPageChange = (data) => {
    setParams((prev) => ({
      ...prev,
      page: data.selected,
    }));
    const payload = {
      page: data.selected,
      limit: params.limit,
    };
    dispatch(getArticlesWithPage(payload));
  };

  return (
    <>
      <SEO
        title="Artikel Sahabat Alter Indonesia "
        description="Belajar bersama Alter dapat kan banyak pembelajaran yang akan membantu dalam masa perkuliahan."
        keywords="Fakultas kedokteran, Fakultas kedokteran Gigi, Ilmu Gizi, Kebidanan, Keprawatan, Ilmu Farmasi, Alter"
        type="article"
      />

      <Banner backgroundimage={carouselImageValue} fluid></Banner>

      <Container className="my-5">
        <Row>
          <Col lg={12} className="mt-lg-5 mt-md-5">
            <Row className="w-100 flex-wrap justify-content-center align-items-center m-0">
              {isLoadingArticle && (
                <div
                  style={{
                    minHeight: "40vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ThreeDots
                    height="120"
                    width="120"
                    radius="9"
                    color="#07a391"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
              {!isLoadingArticle &&
                articleValue.map((data, i) => (
                  <Col key={data.id} lg={4} md={6} sm={6}>
                    <Link
                      to={handleConverUrl(data.id, data.title)}
                      className="d-flex flex-column justify-content-center align-items-center h-100 mb-5 text-decoration-none"
                    >
                      <CardArticle>
                        <img alt="Sample" src={data.image} />
                        <CardBody>
                          <CardSubtitle className="mb-2 text-muted" tag="h6">
                            <Capsul>{data.date}</Capsul>
                          </CardSubtitle>
                          <CardText tag="h5" className="fw-bold text-black">
                            {data.title}
                          </CardText>
                          <ButtonArticle className="text-primary">
                            {data.button}
                          </ButtonArticle>
                        </CardBody>
                      </CardArticle>
                    </Link>
                  </Col>
                ))}
              {!isLoadingArticle && articleValue.length === 0 && (
                <EmptyContent
                  title="No Data"
                  sx={{
                    "& span.MuiBox-root": { height: 160 },
                  }}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Container>

      {!isLoadingArticle && (
        <FooterCustom
          pageCount={params.total_page}
          forcePage={params.page}
          onPageChange={onPageChange}
          section={false}
          pagination={true}
        />
      )}
    </>
  );
};

export default Articles;
