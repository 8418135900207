import React from "react";
import "./index.css";
import ReactPaginate from "react-paginate";

import ChevronLeft from "assets/icons/chevron-left.svg"
import ChevronRight from "assets/icons/chevron-right.svg"

export default function Pagination(props) {
  const { pageCount, forcePage, onPageChange } = props;

  return (
    <div className="mt-5">
      <ReactPaginate
        nextLabel={
          <div
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ChevronRight} alt="left" />
          </div>
        }
        previousLabel={
          <div
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: "#D9D9D9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={ChevronLeft} alt="left" />
          </div>
        }
        breakLabel={"..."}
        pageCount={pageCount}
        forcePage={forcePage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        containerClassName={"pagination"}
        activeclassName={"active-page"}
        onPageChange={onPageChange}
      />
    </div>
  );
}
