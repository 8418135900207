import React from 'react';
import Wa from "assets/images/wa.png";

const ButtonWA = () => {
    return (
        <>
        <div id="btn-wa">
          <a href="https://wa.me/6285161816009?text=Halo%20admin,%20saya%20mau%20bertanya..." target="_blank" rel="noreferrer nopenner">
            <img src={Wa} alt="wa" />
          </a>
        </div>
        </>
    )
}

export default ButtonWA;