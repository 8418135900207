import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga';
import NavbarCustom from "components/organisms/Layouts/Navigation";
import ButtonWA from "components/organisms/Layouts/ButtonWa";

import Home from "pages/Home";
import About from "pages/About";
import Teams from "pages/Team";
import Studies from "pages/Studies";
import Contact from "pages/Contact";
import Articles from "pages/Article";
import DetailsStudies from "pages/DetailsStudies";
import DetailsUkom from "pages/DetailsStudies/UkomDetails";
import DetailsArticles from "pages/DetailsArticle";
import Ebooks from "pages/Ebooks";
import DetailsEbook from "pages/DetailsEbooks";
import ComingSoonPage from "pages/ComingSoon";

const TRACKING_ID = "G-3W3RB09XYJ";
ReactGA.initialize(TRACKING_ID);

const Router = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <Home />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/about"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <About />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/teams"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <Teams />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/studies"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <Studies />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/ebooks"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <Ebooks />
            <ButtonWA />
          </div>
        }
      />
      <Route
        path="/ebooks/details/:name/:id"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <DetailsEbook />
            <ButtonWA />
          </div>
        }
      />

      <Route
        path="/studies/details/:name/:id"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <DetailsStudies />
            <ButtonWA />
          </div>
        }
      />
      <Route
        path="/ukom/details/:name/:id"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <DetailsUkom />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/articles"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <Articles />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/articles/details/:name/:id"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <DetailsArticles />
            <ButtonWA />
          </div>
        }
      />

      <Route
        exact
        path="/contact"
        element={
          <div className="container-fluid p-0 m-0">
            <NavbarCustom />
            <Contact />
            <ButtonWA />
          </div>
        }
      />
      <Route
        exact
        path="/coming-soon"
        element={<ComingSoonPage />}
      />
    </Routes>
  );
};

export default Router;
