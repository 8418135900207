import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducers";

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //     immutableCheck: false,
  //   }),
});

const { dispatch } = store;

export { store, dispatch };
