import { useState } from 'react'

export const ReadMore = ({ id, text, amountOfWords = 36 }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const splittedText = text.split(' ')
  const itCanOverflow = splittedText.length > amountOfWords
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(' ')
    : text
  const endText = splittedText.slice(amountOfWords - 1).join(' ')
  
  const handleKeyboard = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      setIsExpanded(!isExpanded)
    }
  }

  return (
    <p id={id} className="tw-text-base tw-font-medium tw-text-gray-700 xs:tw-text-base tw-text-center">
      {beginText}
      {itCanOverflow && (
        <>
          {!isExpanded && <span>... </span>}
          <span 
            className={`${!isExpanded && 'tw-hidden'}`} 
            aria-hidden={!isExpanded}
          >
            {endText}
          </span>
          <br />
          <span
            className='tw-text-gray-800 tw-font-semibold tw-ml-2'
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            aria-controls={id}
            onKeyDown={handleKeyboard}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Lebih sedikit' : 'Lihat selengkapnya'}
          </span>
        </>
      )}
    </p>
  )
}