import Cookies from "js-cookie";
import axiosNew from "axios";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "helpers/GatewayService";
import { API_URL } from "helpers/ENVState";
//
import { dispatch } from "redux/store";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingMateri: false,
  error: null,
  materis: [],
  materi: null,
};

const slice = createSlice({
  name: "materi",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Materi all
    getMaterisSuccess(state, action) {
      state.isLoading = false;
      state.materis = action.payload;
    },

    // GET Materi one
    getMateriSuccess(state, action) {
      state.isLoading = false;
      state.materi = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllMateri() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/materi");
      dispatch(slice.actions.getMaterisSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDetailsMateri(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/detail-materi-preview/${key}`);
      dispatch(slice.actions.getMateriSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDetailsUkom(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/detail-ukom-preview/${key}`);
      dispatch(slice.actions.getMateriSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const getLinkCheckout = async (payload) => {
  const users = Cookies.get("user");
  const { token } = JSON.parse(users);
  const axiosClient = axiosNew.create({
    baseURL: API_URL,
    headers: {
      Accept: "application/json",
      token: `Bearer|${token}`,
    },
  });
  try {
    let link = `/api/payment/create/invoice`;
    const response = await axiosClient.post(link, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};
